<template>
  <div style="width: 100%; margin-bottom: 15px">
    <div class="user-data">
      <div class="all-data">
        <div class="damage">
          <div class="data-title">Предотвращённый ущерб</div>
          <div class="data-data">
            {{ formatPrice(sumApplication) }}<span>₽</span>
          </div>
        </div>
        <div class="applications-all-time">
          <div class="data-title">Заявок за всё время</div>
          <div class="data-data">{{ allApplication }}</div>
        </div>
        <div class="appication-success">
          <div class="data-title">Исоплненных заявок</div>
          <div class="data-data">{{ applicationComplete }}</div>
        </div>
      </div>
      <div class="create-appointment" v-if="this.$route.path !== '/balance'">
        <ButtonComponent
          :type="'button'"
          :name="'Создать заявку'"
          :style="'mini-button'"
          @click="visible = true"
        />
      </div>
    </div>
    <Dialog
      v-model:visible="visible"
      modal
      header="Создание заявки"
      :style="{ width: '25rem' }"
    >
      <form class="form-submin" @submit.prevent="createApplication">
        <!-- <p class="form-title">Создание заявки</p> -->
        <input
          class="form-data-input"
          type="text"
          placeholder="Номер ШК"
          required
          v-model="application.number"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="Сумма ШК"
          required
          v-model="application.sum"
        />
        <Select
          v-model="selectedPoint"
          :options="points"
          optionLabel="addressPoint"
          placeholder="Выберите ПВЗ"
          checkmark
          :highlightOnSelect="false"
          class="w-full md:w-56"
          required
          @change="updatePointId"
        />
        <Select
          v-model="selectedTypeOfBid"
          :options="typeOfBids"
          optionLabel="name"
          placeholder="Выберите тип заявки"
          checkmark
          :highlightOnSelect="false"
          class="w-full md:w-56"
          required
          @change="updateTypeOfBid"
        />
        <Select
          v-model="selectedTypeOfChallenging"
          :options="typeOfChallengings"
          optionLabel="name"
          placeholder="Выберите тип оспаривания"
          checkmark
          :highlightOnSelect="false"
          class="w-full md:w-56"
          required
          @change="updateTypeOfChallenging"
        />
        <Textarea
          v-model="application.description"
          rows="5"
          autoResize
          cols="30"
          placeholder="Детали"
          required
        />
        <ButtonComponent
          :type="'submit'"
          :name="'Добавить'"
          :style="'mini-button'"
        />
      </form>
    </Dialog>
    <Toast />
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent.vue";
import Dialog from "primevue/dialog";
import axios from "axios";
import Select from "primevue/select";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import { mapMutations } from "vuex";

export default {
  components: {
    ButtonComponent,
    Dialog,
    Select,
    Textarea,
    Toast,
  },
  data() {
    return {
      allApplication: 0,
      applicationComplete: 0,
      sumApplication: 0,
      selectedPoint: null,
      selectedTypeOfBid: null, // Промежуточная переменная для типа заявки
      selectedTypeOfChallenging: null, // Промежуточная переменная для типа оспаривания
      visible: false,
      points: [],
      typeOfBids: [{ name: "Ускоренный" }, { name: "Обычный" }],
      typeOfChallengings: [
        { name: "Брак" },
        { name: "Зависший" },
        { name: "Подмена" },
        { name: "Штраф" },
      ],
      application: {
        number: "",
        sum: "",
        name: "",
        typeOfBid: "", // Строка для хранения значения name
        typeOfChallenging: "", // Строка для хранения значения name
        description: "",
        pointId: {
          id: "",
        },
      },
    };
  },
  methods: {
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getLengthApplication() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      axios
        .get("https://wb-backend-f9df5.amvera.io/application/find-all-length", {
          headers,
        })
        .then((response) => {
          const storedData = localStorage.getItem("allApplication");
          if (storedData !== response.data.toString()) {
            this.allApplication = response.data;

            localStorage.setItem("allApplication", this.allApplication);
          } else {
            this.allApplication = storedData;
          }
        })
        .catch((error) => console.log(error));
    },
    getPoint() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };
      axios
        .get("https://wb-backend-f9df5.amvera.io/point/find-all", {
          headers,
        })
        .then((response) => {
          this.points = response.data;
        })
        .catch((error) => console.log(error));
    },
    getApplicationComplete() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      axios
        .get("https://wb-backend-f9df5.amvera.io/application/find-complete", {
          headers,
        })
        .then((response) => {
          this.applicationComplete = response.data.length;

          const applications = response.data; // Предполагаем, что response.data — это массив заявок

          // Подсчет общей суммы
          const totalSum = applications.reduce((accumulator, application) => {
            return accumulator + application.sum;
          }, 0);
          this.sumApplication = totalSum; // Если нужно сохранить сумму в переменной компонента
        })
        .catch((error) => console.log(error));
    },
    updatePointId(selectedPoint) {
      this.application.pointId.id = selectedPoint.id;
    },
    updateTypeOfBid(selectedBid) {
      this.application.typeOfBid = selectedBid.name; // Сохраняем только name
    },
    updateTypeOfChallenging(selectedChallenging) {
      this.application.typeOfChallenging = selectedChallenging.name; // Сохраняем только name
    },
    // ...mapMutations(["ADD_APPOINTMENT"]),
    createApplication() {
      // Проверяем, что все поля, включая промежуточные переменные, заполнены
      if (
        !this.application.number ||
        !this.application.sum ||
        !this.selectedTypeOfBid || // Проверка выбранного типа заявки
        !this.selectedTypeOfChallenging || // Проверка выбранного типа оспаривания
        !this.application.description ||
        !this.selectedPoint // Проверка выбранного ПВЗ
      ) {
        this.showWarning(); // Вывод предупреждения
        return;
      }

      // Сохраняем значения в application перед отправкой
      this.application.typeOfBid = this.selectedTypeOfBid.name;
      this.application.typeOfChallenging = this.selectedTypeOfChallenging.name;
      this.updatePointId(this.selectedPoint);

      // Отправка данных на сервер
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      axios
        .post(
          "https://wb-backend-f9df5.amvera.io/application/create",
          this.application,
          {
            headers,
          }
        )
        .then((response) => {
          // this.ADD_APPOINTMENT(response.data);
          // this.$store.dispatch("fetchAppointments"); // Перезагружаем данные заявок
          this.showSuccess();
          this.visible = false;
          // Очищаем форму
          this.application = {
            number: "",
            sum: "",
            name: "",
            typeOfBid: "",
            typeOfChallenging: "",
            description: "",
            pointId: {
              id: "",
            },
          };
          this.selectedPoint = null;
          this.selectedTypeOfBid = null;
          this.selectedTypeOfChallenging = null;
        })
        .catch((error) => {
          console.error("Ошибка при создании заявки", error);
        });
    },
    showSuccess() {
      this.$toast.add({
        severity: "success",
        summary: "Заявка отправлена!",
        detail: "Ожидайте, ваша заявка на рассмотрении",
        life: 3000,
      });
    },
    showWarning() {
      this.$toast.add({
        severity: "warn",
        summary: "Не все поля заполнены!",
        detail: "",
        life: 3000,
      });
    },
  },
  created() {
    this.getLengthApplication();
    this.getPoint();
  },
  mounted() {
    this.getLengthApplication();
    this.getApplicationComplete();
    this.getPoint();
  },
  watch: {
    $route() {
      this.getLengthApplication();
    },
  },
};
</script>

<style scoped>
.all-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-data {
  width: 100%;
}

.all-data {
  border-radius: 20px;
  padding: 25px;
  border: 1px solid rgb(234, 228, 236);
  margin-bottom: 15px;
}

.data-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgb(40, 28, 45);
  margin-bottom: 54px;
}

.data-data {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.data-data > span {
  margin-left: 5px;
}

.form-submin {
  display: flex;
  flex-direction: column;
  /* width: 280px; */
}

.form-title {
  color: rgb(40, 28, 45);
  margin-bottom: 5px;
}

.form-data-input {
  margin-bottom: 10px;
  border: none;
  padding: 15px;
  height: 45px;
  background-color: rgb(234, 228, 236);
  border-radius: 15px;
  color: rgb(97, 83, 103);
  margin-top: 5px;
}

.form-data-input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgb(97, 83, 103);
}

@media screen and (max-width: 765px) {
  .data-title {
    font-size: 12px;
  }
  .data-data {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .data-title {
    font-size: 12px;
  }
  .data-data {
    font-size: 16px;
  }
}
</style>
