import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import RegisterView from "../views/RegisterView.vue";
import LoginView from "../views/LoginView.vue";
import UserView from "../views/UserView.vue";
import ProfileView from "../views/ProfileView.vue";
import AdminView from "../views/AdminView.vue";
import AdminApplication from "../views/AdminApplication";
import AdminApplicationCreate from "../views/AdminApplicationCreate.vue";
import BalanceView from "../views/BalanceView.vue";
import { jwtDecode } from "jwt-decode";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/registration",
    name: "register",
    component: RegisterView,
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (token) {
        next({ path: "/" });
      } else next();
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (token) {
        next({ path: "/" });
      } else next();
    },
  },
  {
    path: "/user",
    name: "user",
    component: UserView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/balance",
    name: "balance",
    component: BalanceView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (!token) {
        next({ path: "/" });
      } else {
        const tokenDecoder = jwtDecode(token);
        const role = tokenDecoder.role;
        if (role[0].authority !== "ADMIN") {
          next({ path: "/" });
        } else next();
      }
    },
  },
  {
    path: "/admin/application",
    name: "application",
    component: AdminApplication,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (!token) {
        next({ path: "/" });
      } else {
        const tokenDecoder = jwtDecode(token);
        const role = tokenDecoder.role;
        if (role[0].authority !== "ADMIN" && role[0].authority !== "MANAGER") {
          next({ path: "/" });
        } else next();
      }
    },
  },
  {
    path: "/admin/application/create",
    name: "create",
    component: AdminApplicationCreate,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (!token) {
        next({ path: "/" });
      } else {
        const tokenDecoder = jwtDecode(token);
        const role = tokenDecoder.role;
        if (role[0].authority !== "ADMIN") {
          next({ path: "/" });
        } else next();
      }
    },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");

  if (token) {
    const tokenDecoder = jwtDecode(token);
    if (tokenDecoder.exp + 1000 * 60 * 24 < Date.now()) {
      try {
        const newToken = await refreshJwtToken(refreshToken);
        localStorage.setItem("token", newToken);
      } catch (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        next({
          path: "/login",
          query: { redirect: to.fullPath },
        });
        return;
      }
    }
  }

  if (to.meta.requiresAuth && !token) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

async function refreshJwtToken(refreshToken) {
  const response = await fetch(
    "https://wb-backend-f9df5.amvera.io/auth/refresh",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: refreshToken }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to refresh token");
  }

  const data = await response.json();
  return data.token;
}

export default router;
