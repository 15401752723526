import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    appointments: [],
  },
  getters: {},
  mutations: {
    ADD_APPOINTMENT(state, appointment) {
      state.appointments.push(appointment);
    },
    SET_APPOINTMENTS(state, appointments) {
      state.appointments = appointments;
    },
  },
  actions: {
    fetchAppointments({ commit }) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      axios
        .get("https://wb-backend-f9df5.amvera.io/application/find-all", {
          headers,
        })
        .then((response) => {
          commit("SET_APPOINTMENTS", response.data);
        })
        .catch((error) => console.log(error));
    },
  },
  modules: {},
});
