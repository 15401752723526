<template>
  <div class="wrapper">
    <header-component ref="headerComponent"></header-component>
    <div class="full-width-container"> <!-- Блок на всю ширину экрана -->
    </div>
    <div class="container-xxl"> <!-- Основной контейнер с ограниченной шириной -->
      <div class="container">
        <router-view />
      </div>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent";

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  },
  provide() { // Provide для передачи функции
    return {
      updateBalance: this.updateBalance,
    };
  },
  methods: {
    updateBalance() {
      this.$refs.headerComponent.userData(); // Вызываем userData в HeaderComponent
    },
  },
}
</script>

<style>
*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../src/assets/font/sf/SF-Pro-Display-Light.otf");
}

html, body {
  height: 100%;
  font-family: "SF Pro Display",serif;
  background-color: white;
  color: rgb(40, 28, 45);
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.container-xxl {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1560px;
  margin: 0 auto;
  padding: 0 20px; /* Дополнительный отступ */
  width: 100%;
}

.container {
  max-width: 1400px; /* Ограничение ширины основного контента */
  margin: 0 auto;
  width: 100%;
  flex-grow: 1;
}

.container > * {
  width: 100%;
  box-sizing: border-box;
}

/* Новый контейнер для элементов на всю ширину */
.full-width-container {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0; /* Цвет фона для примера */
}

/* Сили для списков */
li {
  list-style-type: none;
}

/* Стили для ссылок */
a {
  text-decoration: none;
  color: black;
}

/* Стили для primevue */
.p-toast-detail {
  color: black !important;
}

/* Стили для диалоговых окон */
.p-dialog, .p-component {
  background-color: white !important;
  color: balck !important;
}

.p-dialog-title {
  color: black !important;
}

/* Стили для выпадающих списков */
.p-select-option {
  color: rgb(97, 83, 103) !important;
}

.p-select-option:hover, .p-select-label:hover, .p-placeholder:hover {
  background-color: rgb(234, 228, 236) !important;
}

.p-select-label, .p-placeholder {
  color: rgb(97, 83, 103) !important;
  border: none !important;
  background-color: rgb(234, 228, 236)!important;
}

.p-select-label {
  margin-bottom: 15px !important;
  border: none !important;
  padding: 15px !important;
  height: 45px !important;
  background-color: rgb(234, 228, 236) !important;
  border-radius: 15px !important;
  color: rgb(97, 83, 103) !important;
}

.p-select {
  border: none !important;
}

.p-select-dropdown {
  display: none !important;
}

/* Стили для textarea */
.p-textarea {
  color: rgb(97, 83, 103) !important;
  border: none !important;
  background-color: rgb(234, 228, 236) !important;
  margin-bottom: 15px !important;
}

.p-toast {
  background-color: transparent !important;
}

/* Стили для пагинации */
.p-paginator-page-selected {
  background-color: rgb(234, 228, 236) !important;
  color: rgb(97, 83, 103) !important;
}

/* Стили для menubar */
.p-menubar-item-content {
  background-color: white !important;
  color: black !important;
}

.p-menubar {
  border: none !important;
}

.p-menubar-submenu {
  background-color: transparent !important;
  border: none !important;
}

.p-inputtext {
  color: rgb(97, 83, 103) !important;
  border: 1px solid rgb(227, 227, 227) !important;
  padding: 13px 10px !important;
  max-width: 476px !important;
  margin-bottom: 20px;
}
</style>
