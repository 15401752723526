<template>
  <div>
    <div class="application-all">
      <div class="user-title">Заявки</div>
      <div class="user-subtitle">
        Нажмите на колонку ID, чтобы отредактировать
      </div>
      <div class="user-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Номер</th>
              <th>Сумма</th>
              <th>Описание</th>
              <th>ПВЗ</th>
              <th>Отправитель</th>
              <th>Тип заявки</th>
              <th>Тип оспаривания</th>
              <th>Статус</th>
              <th v-if="getRole() == 'admin'">Менеджер</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="application in applications" :key="application.id">
              <td @click="getAndUpdateApplication(application.id)">
                {{ application.id }}
              </td>
              <td>{{ application.number }}</td>
              <td>{{ application.sum }}₽</td>
              <td>{{ application.description }}</td>
              <td>{{ application.pointId.addressPoint }}</td>
              <td>{{ application.userId.fullName }}</td>
              <td>{{ application.typeOfBid }}</td>
              <td>{{ application.typeOfChallenging }}</td>
              <td>{{ application.status }}</td>
              <td v-if="getRole() == 'admin'">
                {{ getManagerFullName(application.managerId) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Paginator
        v-model="page"
        :rows="10"
        :totalRecords="paginationAll"
        @page="getApplication($event.page)"
      ></Paginator>
    </div>
    <Dialog
      v-model:visible="visible"
      modal
      header="Редактирование заявки"
      :style="{ width: '25rem' }"
    >
      <form
        v-if="getRole() == 'admin'"
        class="form-submin"
        @submit.prevent="updateApplication"
      >
        <input
          class="form-data-input"
          type="text"
          placeholder="Номер"
          required
          v-model="application.number"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="Сумма"
          required
          v-model="application.sum"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="Описание"
          required
          v-model="application.description"
        />
        <Select
          v-model="application.pointId.id"
          :options="points"
          optionLabel="addressPoint"
          optionValue="id"
          placeholder="Выберите ПВЗ"
          class="w-full md:w-56"
          required
        />
        <Select
          v-model="application.status"
          :options="statusOptions"
          optionLabel="label"
          optionValue="value"
          placeholder="Выберите статус"
          class="w-full md:w-56"
          required
        />
        <Select
          v-model="application.typeOfBid"
          :options="typeOfBidOptions"
          optionLabel="label"
          optionValue="value"
          placeholder="Выберите тип заявки"
          class="w-full md:w-56"
          required
        />
        <Select
          v-model="application.typeOfChallenging"
          :options="typeOfChallengingOptions"
          optionLabel="label"
          optionValue="value"
          placeholder="Выберите тип оспаривания"
          class="w-full md:w-56"
          required
        />
        <Select
          v-model="selectedManager"
          :options="managers"
          optionLabel="fullName"
          placeholder="Выберите менеджера"
          class="w-full md:w-56"
          required
          @change="application.managerId = selectedManager.id"
        />
        <ButtonComponent
          :type="'submit'"
          :name="'Редактировать'"
          :style="'mini-button'"
        />
      </form>
      <form
        v-if="getRole() == 'manager'"
        class="form-submin"
        @submit.prevent="updateApplication"
      >
        <!-- <input
          class="form-data-input"
          type="text"
          placeholder="Номер"
          required
          v-model="application.number"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="Сумма"
          required
          v-model="application.sum"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="Описание"
          required
          v-model="application.description"
        /> -->
        <Select
          v-model="application.status"
          :options="statusOptions"
          optionLabel="label"
          optionValue="value"
          placeholder="Выберите статус"
          class="w-full md:w-56"
          required
        />
        <!-- <Select
          v-model="application.typeOfBid"
          :options="typeOfBidOptions"
          optionLabel="label"
          optionValue="value"
          placeholder="Выберите тип заявки"
          class="w-full md:w-56"
          required
        />
        <Select
          v-model="application.typeOfChallenging"
          :options="typeOfChallengingOptions"
          optionLabel="label"
          optionValue="value"
          placeholder="Выберите тип оспаривания"
          class="w-full md:w-56"
          required
        />
        <Select
          v-model="selectedManager"
          :options="managers"
          optionLabel="fullName"
          placeholder="Выберите менеджера"
          class="w-full md:w-56"
          required
          @change="application.managerId = selectedManager.id"
        /> -->
        <ButtonComponent
          :type="'submit'"
          :name="'Редактировать'"
          :style="'mini-button'"
        />
      </form>
    </Dialog>
    <Toast />
  </div>
</template>

<script>
import axios from "axios";
import Paginator from "primevue/paginator";
import Select from "primevue/select";
import ButtonComponent from "@/components/ButtonComponent.vue";
import Dialog from "primevue/dialog";
import Toast from "primevue/toast";
import { jwtDecode } from "jwt-decode";

export default {
  components: {
    Paginator,
    ButtonComponent,
    Dialog,
    Select,
    Toast,
  },
  data() {
    return {
      applications: [],
      paginationAll: 10,
      page: 0,
      visible: false,
      managers: [],
      points: [],
      application: {
        id: null,
        number: "",
        sum: "",
        description: "",
        status: "",
        typeOfBid: "",
        typeOfChallenging: "",
        managerId: null,
        pointId: null,
      },
      selectedManager: null,
      statusOptions: [
        { label: "SEND", value: "SEND" },
        { label: "IN_PROGRESS", value: "IN_PROGRESS" },
        { label: "COMPLETED", value: "COMPLETED" },
      ],
      typeOfBidOptions: [
        { label: "Ускоренный", value: "Ускоренный" },
        { label: "Обычный", value: "Обычный" },
      ],
      typeOfChallengingOptions: [
        { label: "Брак", value: "Брак" },
        { label: "Зависший", value: "Зависший" },
        { label: "Подмена", value: "Подмена" },
        { label: "Штраф", value: "Штраф" },
      ],
    };
  },
  methods: {
    getApplication(page) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      const tokenDecoder = jwtDecode(token);
      const role = tokenDecoder.role;
      if (role[0].authority == "ADMIN") {
        axios
          .get(
            `https://wb-backend-f9df5.amvera.io/application/find?size=10&page=${page}`,
            {
              headers,
            }
          )
          .then((response) => {
            this.applications = response.data.content;
            this.paginationAll = response.data.totalPages * 10;
          })
          .catch((error) => console.log(error));
      } else if (role[0].authority == "MANAGER") {
        axios
          .get(
            `https://wb-backend-f9df5.amvera.io/application/find-manager?size=10&page=${page}`,
            {
              headers,
            }
          )
          .then((response) => {
            this.applications = response.data.content;
            this.paginationAll = response.data.totalPages * 10;
          })
          .catch((error) => console.log(error));
      }
    },
    getManager() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      if (this.getRole() == "admin") {
        axios
          .get("https://wb-backend-f9df5.amvera.io/user/find-manager", {
            headers,
          })
          .then((response) => {
            this.managers = response.data;
          })
          .catch((error) => console.log(error));
      }
    },
    getAndUpdateApplication(id) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      axios
        .get(`https://wb-backend-f9df5.amvera.io/application/find/${id}`, {
          headers,
        })
        .then((response) => {
          const getApplication = response.data;

          // Устанавливаем данные заявки
          this.application.id = getApplication.id;
          this.application.number = getApplication.number;
          this.application.sum = getApplication.sum;
          this.application.description = getApplication.description;
          this.application.status = getApplication.status;
          this.application.typeOfBid = getApplication.typeOfBid;
          this.application.typeOfChallenging = getApplication.typeOfChallenging;
          this.application.managerId = getApplication.managerId;
          this.application.pointId = getApplication.pointId;

          // Устанавливаем выбранного менеджера
          this.selectedManager = this.managers.find(
            (manager) => manager.id === getApplication.managerId
          );
          axios
            .get(
              `https://wb-backend-f9df5.amvera.io/point/find-by-user/${getApplication.pointId.user.id}`,
              { headers }
            )
            .then((response) => {
              this.points = response.data;
            })
            .catch((error) => {
              console.log(error);
            });

          this.visible = true;
        })
        .catch((error) => console.log(error));
    },
    updateApplication() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const updatedApplication = {
        number: this.application.number,
        sum: this.application.sum,
        description: this.application.description,
        status: this.application.status,
        typeOfBid: this.application.typeOfBid,
        typeOfChallenging: this.application.typeOfChallenging,
        managerId: this.selectedManager ? this.selectedManager.id : null,
        pointId: {
          id: this.application.pointId.id,
        },
      };

      axios
        .patch(
          `https://wb-backend-f9df5.amvera.io/application/update/${this.application.id}`,
          updatedApplication,
          { headers }
        )
        .then((response) => {
          console.log(response.data);
          this.visible = false;
          this.getApplication(this.page);
        })
        .catch((error) => {
          this.showWarning(error.response.data);
        });
    },

    getManagerFullName(managerId) {
      const manager = this.managers.find((manager) => manager.id === managerId);
      return manager ? manager.fullName : "";
    },
    getRole() {
      const token = localStorage.getItem("token");
      const tokenDecoder = jwtDecode(token);
      const role = tokenDecoder.role;
      if (role[0].authority == "ADMIN") {
        return "admin";
      } else return "manager";
    },
    showWarning(message) {
      this.$toast.add({
        severity: "warn",
        summary: message + " ₽",
        detail: "",
        life: 3000,
      });
    },
  },
  mounted() {
    this.getApplication(this.page);
    this.getManager();
  },
};
</script>

<style scoped>
.user-container {
  max-width: 100%;
  overflow-x: auto;
}

.user-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #dddddd42;
}

th {
  background-color: #f2f2f2;
  color: #333;
}

tr:hover {
  background-color: #f1f1f1;
}

.form-submin {
  display: flex;
  flex-direction: column;
  /* width: 280px; */
}

.form-title {
  color: rgb(40, 28, 45);
  margin-bottom: 5px;
}

.form-data-input {
  margin-bottom: 15px;
  border: none;
  padding: 15px;
  height: 45px;
  background-color: rgb(234, 228, 236);
  border-radius: 15px;
  color: rgb(97, 83, 103);
}

.form-data-input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgb(97, 83, 103);
}

/* Стили для radio button */
.mydict div {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  justify-content: center;
}

.mydict input[type="radio"] {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.mydict input[type="radio"]:checked + span {
  box-shadow: 0 0 0 0.0625em rgb(132, 10, 121);
  background-color: rgb(255, 242, 254);
  z-index: 1;
  color: rgb(132, 10, 121);
}

label span {
  display: block;
  cursor: pointer;
  background-color: #fff;
  padding: 0.375em 0.75em;
  position: relative;
  margin-left: 0.0625em;
  box-shadow: 0 0 0 0.0625em #b5bfd9;
  letter-spacing: 0.05em;
  color: #3e4963;
  text-align: center;
  transition: background-color 0.5s ease;
}

label:first-child span {
  border-radius: 0.375em 0 0 0.375em;
}

label:last-child span {
  border-radius: 0 0.375em 0.375em 0;
}

@media screen and (max-width: 600px) {
  th,
  td {
    padding: 10px;
    font-size: 14px;
  }
}
</style>
