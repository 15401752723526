<template>
  <div>
    <div class="about-us">
      <div class="about-us-content">
        <h1 class="about-us-content-title">
          Предоставляем услуги по аутсорсингу пунктов выдачи заказов Wildberries
        </h1>
        <h3 class="about-us-content-description">
          Большое описание вашей компании по аутсорсингу будет в этой области,
          придумывать текст буду не я, но он должен быть только в этой области.
        </h3>
        <div class="about-us-content-button">
          <a href="">
            <ButtonComponent
              :type="'button'"
              :name="'Обратится'"
              :style="'button-white'"
            />
          </a>
        </div>
      </div>
      <!-- Иформационный блок -->
      <div class="about-us-info">
        <div class="about-us-info-container">
          <div class="about-us-info-title">О нас</div>
          <div class="about-us-info-description">
            Больше информации вашей комании по аутсорсингу будет в этой
          </div>
        </div>
      </div>
      <!--      Блок с сетями-->
      <div class="about-us-contact">
        <div class="about-us-contact-container">
          <!-- <div class="about-us-contact-container-button">
            <button type="button" class="button-arrow">
              Перейти к услугам
              <svg
                width="28.000000"
                height="28.000000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <desc>Created with Pixso.</desc>
                <defs />
                <rect
                  id="arrow.forward"
                  rx="-0.500000"
                  width="20.761700"
                  height="16.777300"
                  transform="translate(3.500000 5.500000)"
                  fill="#FFFFFF"
                  fill-opacity="0"
                />
                <g opacity="0.000000">
                  <path
                    id="Vector"
                    d="M3 5L24.76 5L24.76 22.77L3 22.77L3 5Z"
                    fill="#FFFFFF"
                    fill-opacity="1.000000"
                    fill-rule="evenodd"
                  />
                </g>
                <path
                  id="Vector"
                  d="M24.76 13.88C24.76 13.58 24.64 13.32 24.41 13.09L16.64 5.35C16.38 5.09 16.13 5 15.85 5C15.28 5 14.83 5.42 14.83 6C14.83 6.28 14.92 6.55 15.11 6.74L17.74 9.41L22.39 13.66L22.62 13.07L18.85 12.83L4.03 12.83C3.42 12.83 3 13.27 3 13.88C3 14.49 3.42 14.92 4.03 14.92L18.85 14.92L22.62 14.69L22.39 14.11L17.74 18.34L15.11 21.01C14.92 21.19 14.83 21.47 14.83 21.75C14.83 22.34 15.28 22.76 15.85 22.76C16.13 22.76 16.38 22.66 16.61 22.43L24.41 14.66C24.64 14.44 24.76 14.17 24.76 13.88Z"
                  fill="#FFFFFF"
                  fill-opacity="1.000000"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div> -->
          <div class="about-us-contact-list">
            <div class="contact-list">
              <a href="" target="_blank">
                <div class="contact">
                  <img
                    class="contact-list-img"
                    :src="require('../assets/img/viber.png')"
                    alt="viber"
                  />
                  <div class="contact-list-text">+79999999999</div>
                </div>
              </a>
              <a href="" target="_blank">
                <div class="contact">
                  <img
                    class="contact-list-img"
                    :src="require('../assets/img/whatsapp.png')"
                    alt="whatsapp"
                  />
                  <div class="contact-list-text">+79999999999</div>
                </div>
              </a>
              <a href="" target="_blank">
                <div class="contact">
                  <img
                    class="contact-list-img"
                    :src="require('../assets/img/contact.png')"
                    alt="contact"
                  />
                  <div class="contact-list-text">+79999999999</div>
                </div>
              </a>
              <a href="" target="_blank">
                <div class="contact">
                  <img
                    class="contact-list-img"
                    :src="require('../assets/img/telegram.png')"
                    alt="telegram"
                  />
                  <div class="contact-list-text">@login</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--      Окружность-->
      <!-- <div class="circle-1"></div>
      <div class="circle-2"></div> -->
      <!--      Блок с услугами-->
      <div class="service" id="service">
        <h1 class="service-title">Наши услуги по аутсорсингу</h1>
        <div class="service-tariffs">
          <div class="service-tariff">
            <div class="tariff-name">ТАРИФ 1</div>
            <div class="tariff-container-1">
              <div class="tariff-circle-1"></div>
              <div class="tariff-circle-2"></div>
              <div class="tariff-price-container">
                <div class="tariff-price">56₽</div>
              </div>
              <div class="substitution">
                <div class="substitution-title">Оспорить подмену товара</div>
                <ul class="substitution-ul">
                  <li class="substitution-li">Текст</li>
                  <li class="substitution-li">Текст</li>
                  <li class="substitution-li">Текст</li>
                  <li class="substitution-li">Текст</li>
                  <li class="substitution-li">Текст</li>
                </ul>
              </div>
            </div>
            <div class="tariff-order">Заказать</div>
          </div>
          <div class="service-tariff">
            <div class="tariff-name">ТАРИФ 2</div>
            <div class="tariff-container-2">
              <div class="tariff-circle-3"></div>
              <div class="tariff-circle-4"></div>
              <div class="tariff-circle-5"></div>
              <div class="tariff-price-container">
                <div class="tariff-price">57₽</div>
              </div>
              <div class="substitution">
                <div class="substitution-title">Оспорить брак</div>
                <ul class="substitution-ul">
                  <li class="substitution-li">Текст</li>
                  <li class="substitution-li">Текст</li>
                  <li class="substitution-li">Текст</li>
                  <li class="substitution-li">Текст</li>
                  <li class="substitution-li">Текст</li>
                </ul>
              </div>
            </div>
            <div class="tariff-order">Заказать</div>
          </div>

          <div class="service-tariff">
            <div class="tariff-name">ТАРИФ 3</div>
            <div class="tariff-container-3">
              <div class="tariff-circle-6"></div>
              <div class="tariff-circle-7"></div>
              <div class="tariff-price-container">
                <div class="tariff-price">58₽</div>
              </div>
              <div class="substitution">
                <div class="substitution-title">Оспорить зависший ШК</div>
                <ul class="substitution-ul">
                  <li class="substitution-li">Текст</li>
                  <li class="substitution-li">Текст</li>
                  <li class="substitution-li">Текст</li>
                  <li class="substitution-li">Текст</li>
                  <li class="substitution-li">Текст</li>
                </ul>
              </div>
            </div>
            <div class="tariff-order">Заказать</div>
          </div>
        </div>
        <div class="tariff-order-container">
          <div class="tariff-order-title">
            Заинтересованы в наших услугах по аутсорсингу?
          </div>
          <div class="tariff-order-button">
            <button type="button" class="button-arrow">
              Регистрация
              <svg
                width="28.000000"
                height="28.000000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <desc>Created with Pixso.</desc>
                <defs />
                <rect
                  id="arrow.forward"
                  rx="-0.500000"
                  width="20.761700"
                  height="16.777300"
                  transform="translate(3.500000 5.500000)"
                  fill="#FFFFFF"
                  fill-opacity="0"
                />
                <g opacity="0.000000">
                  <path
                    id="Vector"
                    d="M3 5L24.76 5L24.76 22.77L3 22.77L3 5Z"
                    fill="#FFFFFF"
                    fill-opacity="1.000000"
                    fill-rule="evenodd"
                  />
                </g>
                <path
                  id="Vector"
                  d="M24.76 13.88C24.76 13.58 24.64 13.32 24.41 13.09L16.64 5.35C16.38 5.09 16.13 5 15.85 5C15.28 5 14.83 5.42 14.83 6C14.83 6.28 14.92 6.55 15.11 6.74L17.74 9.41L22.39 13.66L22.62 13.07L18.85 12.83L4.03 12.83C3.42 12.83 3 13.27 3 13.88C3 14.49 3.42 14.92 4.03 14.92L18.85 14.92L22.62 14.69L22.39 14.11L17.74 18.34L15.11 21.01C14.92 21.19 14.83 21.47 14.83 21.75C14.83 22.34 15.28 22.76 15.85 22.76C16.13 22.76 16.38 22.66 16.61 22.43L24.41 14.66C24.64 14.44 24.76 14.17 24.76 13.88Z"
                  fill="#FFFFFF"
                  fill-opacity="1.000000"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  Блок с экономией  -->
    <div class="saving">
      <div class="saving-title">С нами вы сможете сэкономить!</div>
      <div class="saving-containers">
        <div class="saving-container-1">
          <div class="saving-circle-1"></div>
          <div class="saving-circle-2"></div>
          <div class="saving-text">Экономьте с каждого оспоренного брака</div>
        </div>

        <div class="saving-container-2">
          <div class="saving-circle-1"></div>
          <div class="saving-circle-2"></div>
          <div class="saving-text">
            Сохраняйте свои деньги пользуюясь нашими услугами
          </div>
        </div>

        <div class="saving-container-3">
          <div class="saving-circle-1"></div>
          <div class="saving-circle-2"></div>
          <div class="saving-text">
            Оспаривайте подмены товара и не платите лишнее
          </div>
        </div>
      </div>

      <div class="saving-order">
        <div class="saving-order-title">Попробуйте наш сервис</div>
        <div class="saving-order-button">
          <a href="/registration">
              <button type="button" class="button-arrow">
              Регистрация
              <svg
                width="28.000000"
                height="28.000000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <desc>Created with Pixso.</desc>
                <defs />
                <rect
                  id="arrow.forward"
                  rx="-0.500000"
                  width="20.761700"
                  height="16.777300"
                  transform="translate(3.500000 5.500000)"
                  fill="#FFFFFF"
                  fill-opacity="0"
                />
                <g opacity="0.000000">
                  <path
                    id="Vector"
                    d="M3 5L24.76 5L24.76 22.77L3 22.77L3 5Z"
                    fill="#FFFFFF"
                    fill-opacity="1.000000"
                    fill-rule="evenodd"
                  />
                </g>
                <path
                  id="Vector"
                  d="M24.76 13.88C24.76 13.58 24.64 13.32 24.41 13.09L16.64 5.35C16.38 5.09 16.13 5 15.85 5C15.28 5 14.83 5.42 14.83 6C14.83 6.28 14.92 6.55 15.11 6.74L17.74 9.41L22.39 13.66L22.62 13.07L18.85 12.83L4.03 12.83C3.42 12.83 3 13.27 3 13.88C3 14.49 3.42 14.92 4.03 14.92L18.85 14.92L22.62 14.69L22.39 14.11L17.74 18.34L15.11 21.01C14.92 21.19 14.83 21.47 14.83 21.75C14.83 22.34 15.28 22.76 15.85 22.76C16.13 22.76 16.38 22.66 16.61 22.43L24.41 14.66C24.64 14.44 24.76 14.17 24.76 13.88Z"
                  fill="#FFFFFF"
                  fill-opacity="1.000000"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
            </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
  name: "HomeView",
  components: {
    ButtonComponent,
  },
};
</script>

<style scoped>
.about-us-content-title {
  font-size: 40px;
  font-weight: 500;
  line-height: 67px;
  margin-bottom: 20px;
}

.about-us-content-description {
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 158px;
}

.about-us-content {
  text-align: center;
  color: white;
  padding: 40px;
  border-radius: 30px;
  background-image: linear-gradient(
    to right,
    rgb(189, 0, 118),
    rgb(119, 0, 118)
  ); /* Градиент слева направо */
  margin-bottom: 17px;
}

.about-us {
  margin-bottom: 188px;
}

/* Стили для информационного блока */

.about-us-info {
  padding: 40px;
  border: 1px rgba(0, 0, 0, 0.08) solid;
  border-radius: 30px;
  margin-bottom: 44px;
  background-color: transparent;
}

.about-us-info-container {
}

.about-us-info-title {
  font-weight: 500;
  font-size: 40px;
  line-height: 67px;
  margin-bottom: 20px;
}

.about-us-info-description {
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: rgb(140, 140, 140);
}

/* Стили для блока с сетями */
.button-arrow {
  background-color: rgb(171, 30, 158);
  border: none;
  padding: 20px 30px;
  border-radius: 40px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-arrow > svg {
  margin-left: 15px;
}

.about-us-contact-container {
  display: flex;
  justify-content: center;
}

.contact {
  display: grid;
  justify-items: center;
  margin-right: 40px;
}

.contact-list-text {
  margin-top: 10px;
  color: #646464;
}

.contact-list {
  display: flex;
}

/* Стили для окружности */
.circle-1 {
  background-color: rgba(183, 0, 167, 0.6);
  width: 750px;
  height: 750px;
  border-radius: 50%;
  position: absolute;
  z-index: -2;
  filter: blur(884px);
  left: -231px;
  overflow: hidden;
  top: 1202px;
}

.circle-2 {
  background-color: rgba(183, 0, 167, 0.6);
  width: 750px;
  height: 750px;
  border-radius: 50%;
  position: absolute;
  z-index: -2;
  filter: blur(884px);
  left: 1089px;
  overflow: hidden;
  top: 1153px;
}

/* Стили для блока с услугми */
.service {
  margin-top: 190px;
}
.service-title {
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 47px;
}

/* Карточки тарифов */

.service-tariffs,
.service-tariff {
  background-color: transparent;
}

.service-tariffs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 51px;
}

.tariff-container-1 {
  background-color: rgb(189, 0, 118);
  position: relative;
  overflow: hidden;
}

.tariff-container-2 {
  background-color: #960076;
  position: relative;
  overflow: hidden;
}

.tariff-container-3 {
  background-color: #770076;
  position: relative;
  overflow: hidden;
}

.tariff-circle-1 {
  position: absolute;
  width: 146px;
  height: 146px;
  border-radius: 50%;
  background-color: #ff65db;
  left: 375px;
  top: 60px;
  opacity: 0.8;
}

.tariff-circle-2 {
  position: absolute;
  width: 146px;
  height: 146px;
  border-radius: 50%;
  background-image: radial-gradient(rgb(255, 209, 255), rgb(255, 182, 255));
  left: -132px;
  top: 288px;
  /*opacity: 0.8;*/
}

.tariff-circle-3 {
  position: absolute;
  width: 146px;
  height: 146px;
  border-radius: 50%;
  background-color: #fd67dd;
  left: -130px;
  top: 52px;
  /*opacity: 0.8;*/
}

.tariff-circle-4 {
  position: absolute;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-image: radial-gradient(#fc66dc, #e04ac0);
  left: 323px;
  top: 139px;
  /*opacity: 0.8;*/
}

.tariff-circle-5 {
  position: absolute;
  width: 97px;
  height: 97px;
  border-radius: 50%;
  background-image: radial-gradient(#ffeaff, #ff90ff);
  left: 357px;
  top: 241px;
  /*opacity: 0.8;*/
}

.tariff-circle-6 {
  position: absolute;
  width: 146px;
  height: 146px;
  border-radius: 50%;
  background-image: radial-gradient(#e36ce2, #de67dd);
  left: -127px;
  top: 288px;
  /*opacity: 0.8;*/
}

.tariff-circle-7 {
  position: absolute;
  width: 343px;
  height: 343px;
  border-radius: 50%;
  background-image: radial-gradient(rgb(255, 209, 255), rgb(255, 182, 255));
  left: 296px;
  top: 116px;
  /*opacity: 0.8;*/
}

.tariff-name {
  color: black;
  text-transform: uppercase;
  text-align: center;
  padding: 14px 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.service-tariff {
  width: 417px;
  background-color: rgb(255, 255, 255);
  min-height: 560px;
  border-radius: 35px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.tariff-price-container {
  padding-top: 20px;
}

.tariff-price {
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  padding: 15px 25px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  margin: 0 20px 20px 32px;
}

.substitution {
  margin-left: 20px;
}

.substitution-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  margin-bottom: 32px;
  color: white;
}

.substitution-ul {
  color: white;
}

.substitution-li {
  list-style-type: disc;
  margin-left: 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 35px;
  border-bottom: 1px rgba(0, 0, 0, 0.08) solid;
  padding: 10px 0;
  margin-bottom: 6px;
}

.tariff-order {
  text-align: center;
  text-transform: uppercase;
  padding: 10px 14px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  cursor: pointer;
}

.tariff-order-container {
  display: grid;
  justify-content: center;
}

.tariff-order-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 55px;
}

.tariff-order-button {
  display: flex;
  justify-content: center;
}

/* Стили для блока с экономией */
.saving-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  margin-bottom: 54px;
}

.saving-container-1 {
  background-color: rgb(189, 0, 118);
  display: grid;
  justify-content: center;
  align-content: center;
  height: 169px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
}

.saving-circle-1 {
  position: absolute;
  width: 590px;
  height: 590px;
  background-image: radial-gradient(rgba(153, 153, 153, 0), rgb(255, 255, 255));
  border-radius: 50%;
  top: 73px;
  border: 1px solid rgb(255, 255, 255);
  opacity: 0.3;
}

.saving-circle-2 {
  position: absolute;
  width: 590px;
  height: 590px;
  background-image: radial-gradient(rgba(153, 153, 153, 0), rgb(255, 255, 255));
  border-radius: 50%;
  top: 27px;
  border: 1px solid rgb(255, 255, 255);
  opacity: 0.3;
  right: -173px;
}

.saving-container-2 {
  background-color: rgb(150, 0, 118);
  display: grid;
  justify-content: center;
  align-content: center;
  height: 169px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
}

.saving-container-2 > .saving-circle-1 {
  position: absolute;
  width: 590px;
  height: 590px;
  background-image: radial-gradient(rgba(153, 153, 153, 0), rgb(255, 255, 255));
  border-radius: 50%;
  top: -129px;
  border: 1px solid rgb(255, 255, 255);
  opacity: 0.3;
}

.saving-container-2 > .saving-circle-2 {
  position: absolute;
  width: 590px;
  height: 590px;
  background-image: radial-gradient(rgba(153, 153, 153, 0), rgb(255, 255, 255));
  border-radius: 50%;
  top: -171px;
  border: 1px solid rgb(255, 255, 255);
  opacity: 0.3;
}

.saving-container-3 {
  background-color: rgb(119, 0, 118);
  display: grid;
  justify-content: center;
  align-content: center;
  height: 169px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  margin-bottom: 55px;
}

.saving-container-3 > .saving-circle-1 {
  position: absolute;
  width: 590px;
  height: 590px;
  background-image: radial-gradient(rgba(153, 153, 153, 0), rgb(255, 255, 255));
  border-radius: 50%;
  top: -343px;
  border: 1px solid rgb(255, 255, 255);
  opacity: 0.3;
}

.saving-container-3 > .saving-circle-2 {
  position: absolute;
  width: 590px;
  height: 590px;
  background-image: radial-gradient(rgba(153, 153, 153, 0), rgb(255, 255, 255));
  border-radius: 50%;
  top: -373px;
  border: 1px solid rgb(255, 255, 255);
  opacity: 0.3;
}

.saving {
  margin-bottom: 100px;
}

.saving-text {
  color: white;
  font-size: 26px;
  font-weight: 600;
  line-height: 31px;
  word-break: break-word;
  max-width: 282px;
  text-align: center;
}

.saving-order-title {
  color: black;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 55px;
  text-align: center;
}

.saving-order-button {
  display: grid;
  justify-content: center;
}

@media screen and (max-width: 1300px) {
  .service-tariffs {
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
  }
  .service-tariff {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 840px) {
  .service-tariffs {
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .about-us-content-title {
    font-size: 30px;
  }

  .about-us-content-description {
    font-size: 20px;
  }
}

@media screen and (max-width: 584px) {
  .contact-list {
    justify-content: space-around;
  flex-wrap: wrap;
  }
  .contact-list:last-child > a {
    width: 152px;
  }
  .contact-list > a {
    margin-bottom: 20px;
  }
}

</style>
