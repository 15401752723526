<template>
  <div class="header-line">
    <header>
      <nav>
        <div class="mini-nav-list">
          <div class="button-container-nav">
            <a href="/" class="link">
              <button class="button">
                <svg
                  class="icon"
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 1024 1024"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 0 0-44.4 0L77.5 505a63.9 63.9 0 0 0-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0 0 18.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"
                  ></path>
                </svg>
              </button>
            </a>
            <a href="#service" class="link" v-if="isHomePage">
              <button class="button">
                <svg
                  version="1.1"
                  id="PRICE"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 1800 1800"
                  enable-background="new 0 0 1800 1800"
                  xml:space="preserve"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g id="Layer">
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                fill="#ffffff"
                                d="M980.141,1426.945H799.02v-118.506c-69.393-6.158-141.632-26.024-190.641-53.104l-20.991-11.607 l52.338-204.102l35.001,19.166c33.877,18.551,103.21,49.678,186.896,49.678c43.264,0,86.914-13.717,86.914-44.368 c0-20.55-13.207-44.022-108.99-76.386C727.884,950.146,599.67,887.562,599.67,735.901c0-118.03,78.008-208.647,205.43-241.258 V373.055h181.121v108.638c76.854,6.214,127.293,26.167,159.665,41.397l23.395,11.01l-52.706,198.537l-34.932-16.757 c-30.332-14.56-81.1-38.932-160.461-38.932c-27.462,0-73.538,4.973-73.538,38.288c0,25.207,67.061,52.896,125.491,74.206 c156.457,54.756,223.372,131.853,223.372,257.641c0,62.643-21.977,119.475-63.568,164.353 c-37.588,40.571-90.052,69.527-152.798,84.567V1426.945z M861.015,1364.95h57.132v-120.313l25.729-4.437 c117.589-20.272,190.636-94.271,190.636-193.118c0-81.316-30.988-146.326-182.237-199.261 c-91.338-33.311-166.625-66.172-166.625-132.585c0-48.481,35.607-100.283,135.533-100.283 c68.248,0,119.197,15.615,154.484,30.171l20.221-76.148c-31.758-12.463-77.164-25.047-141.589-26.967l-30.071-0.899v-106.06 h-57.132v110.22L841.792,550c-112.789,21.082-180.128,90.579-180.128,185.901c0,88.71,59.123,146.45,197.689,193.064 c78.537,26.534,151.178,61.268,151.178,135.137c0,64.615-58.448,106.362-148.908,106.362 c-73.782,0-137.615-20.566-181.23-39.836l-20.731,80.85c39.862,17.4,101.68,34.715,171.28,36.791l30.072,0.9V1364.95z"
                              ></path>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path
                                fill="none"
                                d="M1394.443,1646.797l13.552-28.16c-5.872,4.203-11.797,8.346-17.772,12.41L1394.443,1646.797z"
                              ></path>
                            </g>
                            <g>
                              <path
                                fill="#ffffff"
                                d="M894.438,1721.617c-453.04,0-821.616-368.576-821.616-821.614c0-453.044,368.576-821.62,821.616-821.62 c453.043,0,821.627,368.576,821.627,821.62c0,144.397-37.492,280.184-103.18,398.222l61.597,16.502 c66.06-123.746,103.577-264.929,103.577-414.724c0-487.224-396.391-883.615-883.621-883.615 c-487.224,0-883.61,396.391-883.61,883.615c0,487.227,396.386,883.608,883.61,883.608 c154.789,0,300.383-40.053,427.035-110.271l-16.563-61.813C1184.061,1681.513,1043.848,1721.617,894.438,1721.617z"
                              ></path>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path
                                fill="#ffffff"
                                d="M1661.595,1783.611c-7.931,0-15.862-3.027-21.916-9.072l-150.333-150.333l-48.355,100.489 c-5.621,11.686-17.973,18.647-30.824,17.428c-12.904-1.211-23.697-10.326-27.053-22.842l-111.181-414.95 c-2.862-10.698,0.199-22.114,8.026-29.942c7.836-7.836,19.243-10.889,29.941-8.025l414.943,111.188 c12.514,3.355,21.63,14.149,22.841,27.054c1.202,12.903-5.743,25.202-17.427,30.824l-100.49,48.363l150.332,150.324 c5.813,5.813,9.073,13.699,9.073,21.916c0,8.225-3.261,16.104-9.073,21.925l-96.58,96.581 C1677.465,1780.584,1669.525,1783.611,1661.595,1783.611z M1480.368,1540.391c8.147,0,16.052,3.217,21.916,9.081 l159.311,159.311l52.749-52.749l-159.311-159.302c-7.101-7.108-10.326-17.211-8.641-27.122 c1.688-9.903,8.061-18.37,17.116-22.729l64.563-31.076l-282.357-75.659l75.651,282.356l31.066-64.555 c4.358-9.055,12.835-15.43,22.737-17.115C1476.9,1540.537,1478.639,1540.391,1480.368,1540.391z"
                              ></path>
                            </g>
                          </g>
                        </g>
                        <path
                          fill="#ffffff"
                          d="M1140.299,292.544c111.231,0,327.736,121.636,327.736,344.61c0,17.12,13.873,30.997,30.997,30.997 c17.116,0,30.997-13.877,30.997-30.997c0-116.063-51.296-223.666-144.435-302.993c-73.896-62.941-170.182-103.612-245.296-103.612 c-17.116,0-30.998,13.877-30.998,30.998S1123.183,292.544,1140.299,292.544z"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </a>
            <a :href="getRole()" class="link" v-if="isAuthData">
              <button class="button">
                <svg
                  class="icon"
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2.5a5.5 5.5 0 0 1 3.096 10.047 9.005 9.005 0 0 1 5.9 8.181.75.75 0 1 1-1.499.044 7.5 7.5 0 0 0-14.993 0 .75.75 0 0 1-1.5-.045 9.005 9.005 0 0 1 5.9-8.18A5.5 5.5 0 0 1 12 2.5ZM8 8a4 4 0 1 0 8 0 4 4 0 0 0-8 0Z"
                  ></path>
                </svg>
              </button>
            </a>

            <a href="/login" class="link" v-if="!isAuthData">
              <button class="button">
                <p class="button-text">Вход</p>
              </button>
            </a>

            <a href="/registration" class="link" v-if="!isAuthData">
              <button class="button">Регистрация</button>
            </a>

            <router-link
              to="/admin/application"
              class="link"
              v-if="isAdminOrManager"
            >
              <button class="button">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <circle
                      cx="9"
                      cy="6"
                      r="4"
                      stroke="#ffffff"
                      stroke-width="1.5"
                    ></circle>
                    <path
                      d="M15 9C16.6569 9 18 7.65685 18 6C18 4.34315 16.6569 3 15 3"
                      stroke="#ffffff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></path>
                    <path
                      d="M5.88915 20.5843C6.82627 20.8504 7.88256 21 9 21C12.866 21 16 19.2091 16 17C16 14.7909 12.866 13 9 13C5.13401 13 2 14.7909 2 17C2 17.3453 2.07657 17.6804 2.22053 18"
                      stroke="#ffffff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></path>
                    <path
                      d="M18 14C19.7542 14.3847 21 15.3589 21 16.5C21 17.5293 19.9863 18.4229 18.5 18.8704"
                      stroke="#ffffff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></path>
                  </g>
                </svg>
              </button>
            </router-link>

            <router-link
              to="/admin/application/create"
              class="link"
              v-if="isAdminOrManager"
            >
              <button class="button">
                <svg
                  viewBox="0 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>plus-square</title>
                    <desc>Created with Sketch Beta.</desc>
                    <defs></defs>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      sketch:type="MSPage"
                    >
                      <g
                        id="Icon-Set"
                        sketch:type="MSLayerGroup"
                        transform="translate(-100.000000, -1035.000000)"
                        fill="#ffffff"
                      >
                        <path
                          d="M130,1063 C130,1064.1 129.104,1065 128,1065 L104,1065 C102.896,1065 102,1064.1 102,1063 L102,1039 C102,1037.9 102.896,1037 104,1037 L128,1037 C129.104,1037 130,1037.9 130,1039 L130,1063 L130,1063 Z M128,1035 L104,1035 C101.791,1035 100,1036.79 100,1039 L100,1063 C100,1065.21 101.791,1067 104,1067 L128,1067 C130.209,1067 132,1065.21 132,1063 L132,1039 C132,1036.79 130.209,1035 128,1035 L128,1035 Z M122,1050 L117,1050 L117,1045 C117,1044.45 116.552,1044 116,1044 C115.448,1044 115,1044.45 115,1045 L115,1050 L110,1050 C109.448,1050 109,1050.45 109,1051 C109,1051.55 109.448,1052 110,1052 L115,1052 L115,1057 C115,1057.55 115.448,1058 116,1058 C116.552,1058 117,1057.55 117,1057 L117,1052 L122,1052 C122.552,1052 123,1051.55 123,1051 C123,1050.45 122.552,1050 122,1050 L122,1050 Z"
                          id="plus-square"
                          sketch:type="MSShapeGroup"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </router-link>

            <button class="button" @click="logOut" v-if="isAdminOrManager">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M15 12L2 12M2 12L5.5 9M2 12L5.5 15"
                    stroke="#ffffff"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M9.00195 7C9.01406 4.82497 9.11051 3.64706 9.87889 2.87868C10.7576 2 12.1718 2 15.0002 2L16.0002 2C18.8286 2 20.2429 2 21.1215 2.87868C22.0002 3.75736 22.0002 5.17157 22.0002 8L22.0002 16C22.0002 18.8284 22.0002 20.2426 21.1215 21.1213C20.3531 21.8897 19.1752 21.9862 17 21.9983M9.00195 17C9.01406 19.175 9.11051 20.3529 9.87889 21.1213C10.5202 21.7626 11.4467 21.9359 13 21.9827"
                    stroke="#ffffff"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
        </div>
        <div class="nav-list">
          <ul>
            <li>
              <router-link class="link strong-link" to="/"
                ><img
                  class="logo"
                  :src="require('../assets/logo.png')"
                  alt="logotip"
              /></router-link>
            </li>
            <li v-if="isAdminOrManager">
              <router-link to="/admin" class="link link-opacity"
                >Пользователи</router-link
              >
            </li>
            <li v-if="isAdminOrManager">
              <router-link to="/admin/application" class="link link-opacity"
                >Заявки</router-link
              >
            </li>
            <li v-if="isAdminOrManager">
              <router-link
                to="/admin/application/create"
                class="link link-opacity"
                >Создать заявку</router-link
              >
            </li>
            <li v-if="isHomePage">
              <a href="#service" class="link link-opacity">Услуги</a>
            </li>
            <!-- <li v-if="isHomePage">
              <router-link to="" class="link link-opacity"
                >Как оспорить любое решение?</router-link
              >
            </li> -->
          </ul>
        </div>
        <div v-if="!isAuthData" class="nav-reg-and-acc">
          <router-link to="/login" class="link"
            ><p class="login">Вход</p></router-link
          >
          <router-link to="/registration" class="link">
            <ButtonComponent
              :name="'Регистрация'"
              :style="'button'"
              :type="'button'"
            />
          </router-link>
        </div>
        <div v-if="isAuthData" class="nav-reg-and-acc">
          <div class="user-data">
            <div class="user-price">
              {{ formatPrice(userPrice) }}<span>₽</span>
            </div>
            <a :href="getRole()" class="link"
              ><div class="user-name">{{ userName }}</div></a
            >
            <div @click="logOut" v-if="isAdminOrManager" class="user-name">
              Выход
            </div>
            <a :href="getRole()" class="link"><div class="user-icon"></div></a>
            <!-- <div
              class="user-price logout"
              @click="logOut"
              v-if="isAdminOrManager"
            >
              Выход
            </div> -->
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent.vue";
import axios from "axios";
import Menubar from "primevue/menubar";
import MiniHeaderComponent from "@/components/MiniHeaderComponent.vue";
import { jwtDecode } from "jwt-decode";

export default {
  components: {
    ButtonComponent,
    Menubar,
    MiniHeaderComponent,
  },
  data() {
    return {
      isAuthData: false,
      userName: "",
      userPrice: 0.0,
    };
  },
  computed: {
    isHomePage() {
      return this.$route.path === "/";
    },
    isAdminOrManager() {
      const token = localStorage.getItem("token");
      if (!token) {
        return false; // Не авторизован
      }

      try {
        const tokenDecoder = jwtDecode(token);
        const role = tokenDecoder.role[0].authority; // Извлекаем authority

        // Четко проверяем на допустимые роли
        return role === "ADMIN" || role === "MANAGER";
      } catch (error) {
        console.error("Ошибка декодирования токена:", error);
        return false; // Ошибка декодирования, считаем не админом
      }
    },
  },
  created() {
    this.checkAuth();
    this.userData();
  },
  watch: {
    $route() {
      // Проверяем авторизацию при каждом изменении маршрута
      this.checkAuth();
      this.userData();
    },
  },
  methods: {
    checkAuth() {
      this.isAuthData = !!localStorage.getItem("token");
    },
    userData() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      axios
        .get("https://wb-backend-f9df5.amvera.io/user/small", {
          headers,
        })
        .then((response) => {
          this.userName = response.data.fullName;
          this.userPrice = response.data.price;
        })
        .catch((error) => console.log(error));
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getRole() {
      const token = localStorage.getItem("token");
      if (token) {
        const tokenDecoder = jwtDecode(token);
        const role = tokenDecoder.role;
        if (role[0].authority == "ADMIN") {
          return "/admin";
        } else if (role[0].authority == "MANAGER") return "/admin/application";
        else return "/user";
      }
    },
    getRoleUrl() {
      const token = localStorage.getItem("token");
      if (token) {
        const tokenDecoder = jwtDecode(token);
        const role = tokenDecoder.role;
        if (role[0].authority === "ADMIN" || role[0].authority === "MANAGER") {
          return true;
        }
      }
    },
    logOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.href = "/";
    },
  },
  mounted() {
    this.userData();
    this.getRoleUrl();
  },
};
</script>

<style scoped>
header {
  max-width: 1400px;
  margin: 25px auto 25px auto;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.strong-link {
  font-weight: 600;
}

/* Список и ссылки */
.nav-list > ul {
  display: flex;
  align-items: center;
}

.nav-list > ul > li {
  margin-right: 45px;
}

.link-opacity {
  color: rgb(128, 128, 128);
}

.nav-reg-and-acc {
  display: flex;
  align-items: center;
}

.login {
  margin-right: 20px;
}

.header-line {
  border-bottom: 1px solid rgb(237, 236, 237);
  margin-bottom: 67px;
}

/* Стили для пользователя */
.user-data {
  display: flex;
  align-items: center;
}

.user-price {
  margin-right: 15px;
  color: rgb(150, 150, 150);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.user-price > span {
  margin-left: 5px;
}

.user-name {
  margin-right: 15px;
  color: rgb(40, 28, 45);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.user-icon {
  width: 25px;
  height: 25px;
  background-color: rgb(68, 98, 255);
  border-radius: 50%;
}

.logout {
  margin-left: 15px;
  cursor: pointer;
}

.mini-nav-list {
  display: none;
}

.logo {
  width: 30px;
}

@media screen and (max-width: 850px) {
  .nav-list {
    display: none;
  }
  .mini-nav-list {
    display: block;
    margin-bottom: 20px;
  }
  .button-container-nav {
    display: flex;
    background-color: black;
    width: 250px;
    height: 40px;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
  }

  .button {
    outline: 0 !important;
    border: 0 !important;
    /* width: 40px; */
    height: 40px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
  }

  .button:hover {
    transform: translateY(-3px);
  }

  svg {
    width: 25px;
    height: 25px;
  }

  .icon {
    font-size: 20px;
  }
  nav {
    display: grid;
    justify-content: center;
    align-content: center;
    text-align: center;
    justify-items: center;
  }
  .nav-reg-and-acc {
    display: none;
  }
}
</style>
