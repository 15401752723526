<template>
  <div>
    <div class="register-container">
      <div class="register-title">Регистрация</div>
      <div class="register-description">Зарегистрируйте Ваш аккаунт</div>
      <div class="register-form">
        <form action="" class="form-submin" @submit.prevent="submitForm">
          <input
            class="form-data-input"
            type="text"
            placeholder="ФИО"
            required
            v-model="fullName"
          />
          <input
            class="form-data-input"
            type="phone"
            placeholder="Номер телефона"
            required
            v-model="phone"
          />
          <input
            class="form-data-input"
            type="email"
            placeholder="E-Mail"
            required
            v-model="email"
          />
          <input
            class="form-data-input"
            type="login"
            placeholder="Логин"
            required
            v-model="login"
          />
          <input
            class="form-data-input"
            type="password"
            placeholder="Пароль"
            required
            v-model="password"
          />
          <ButtonComponent
            :type="'submit'"
            :name="'Зарегистрироваться'"
            :style="'mini-button'"
          />
        </form>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent.vue";
import axios from "axios";
import Toast from "primevue/toast";

export default {
  components: {
    ButtonComponent,
    Toast,
  },
  data() {
    return {
      fullName: "",
      email: "",
      phone: "",
      login: "",
      password: "",
    };
  },
  methods: {
    submitForm() {
      const formData = new FormData();
      formData.append("fullName", this.fullName);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("login", this.login);
      formData.append("password", this.password);

      axios
        .post("https://wb-backend-f9df5.amvera.io/user/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.showSuccess();

          // Переадресация через 5 секунд
          setTimeout(() => {
            this.$router.push({ path: "/" });
          }, 3000);
        })
        .catch((error) => this.showWarning());
    },

    showSuccess() {
      this.$toast.add({
        severity: "success",
        summary: "Ваша заявка на регистрацию отправлена на модерацию!",
        detail: "Скоро с вами свяжется наш медежер",
        life: 3000,
      });
    },
    showWarning() {
      this.$toast.add({
        severity: "warn",
        summary: "Ошибка!",
        detail: "Такой пользователь уже существует",
        life: 5000,
      });
    },
  },
};
</script>

<style scoped>
.register-container {
  margin: 0 auto 0 auto;
  display: grid;
  justify-content: center;
}

.register-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 20px;
  text-align: center;
}

.register-description {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 39px;
  text-align: center;
}

.form-submin {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.form-data-input {
  margin-bottom: 15px;
  border: none;
  padding: 15px;
  height: 45px;
  background-color: rgb(234, 228, 236);
  border-radius: 15px;
  color: rgb(97, 83, 103);
}

.form-data-input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgb(97, 83, 103);
}
</style>
