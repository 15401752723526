<template>
  <div>
    <div class="all-user-data">
      <UserNavigationComponent />
      <div class="user-data">
        <UserDataComponent />
        <div class="user-applications">
          <div class="user-application">
            <div class="user-application-title">Мои заявки</div>
            <div class="all-appointments">
              <div class="appointments-title">
                <ul class="ul-appointment-title">
                  <li class="li li-appointment">Номер ШК</li>
                  <li class="li li-appointment">Пункт выдачи</li>
                  <li class="li li-appointment">Видеонаблюдение</li>
                  <li class="li li-appointment">Тип заявки</li>
                  <li class="li li-appointment">Тип оспаривания</li>
                  <li class="li li-appointment">Статус</li>
                </ul>
              </div>
              <div
                class="appointments-data"
                v-for="appointment in appointments"
                :key="appointment.id"
              >
                <ul class="ul-appointment-data">
                  <li class="li li-appointment-data id">
                    {{ appointment.id }}
                  </li>
                  <li class="li li-appointment-data">
                    {{ appointment.point.addressPoint }}
                  </li>
                  <li class="li li-appointment-data video">
                    {{ appointment.point.namePoint }}
                  </li>
                  <li class="li li-appointment-data">
                    {{ appointment.typeOfBid }}
                  </li>
                  <li class="li li-appointment-data">
                    {{ appointment.typeOfChallenging }}
                  </li>
                  <li
                    :class="statusClass(appointment.status)"
                    class="li li-appointment-data"
                  >
                    {{ statusText(appointment.status) }}
                  </li>
                </ul>
              </div>
            </div>
            <Paginator
              v-model="page"
              :rows="10"
              :totalRecords="paginationAll"
              @page="getApplication($event.page)"
            ></Paginator>
          </div>
        </div>
      </div>
    </div>
    <div class="user-applications mini">
      <div class="user-application">
        <div class="user-application-title">Мои заявки</div>
        <div class="all-appointments">
          <div class="appointments-title">
            <ul class="ul-appointment-title">
              <li class="li li-appointment id">Номер ШК</li>
              <li class="li li-appointment">Пункт выдачи</li>
              <li class="li li-appointment video">Видеонаблюдение</li>
              <li class="li li-appointment">Тип заявки</li>
              <li class="li li-appointment">Тип оспаривания</li>
              <li class="li li-appointment">Статус</li>
            </ul>
          </div>
          <div
            class="appointments-data"
            v-for="appointment in appointments"
            :key="appointment.id"
          >
            <ul class="ul-appointment-data">
              <li class="li li-appointment-data id">
                {{ appointment.id }}
              </li>
              <li class="li li-appointment-data">
                {{ appointment.point.addressPoint }}
              </li>
              <li class="li li-appointment-data video">
                {{ appointment.point.namePoint }}
              </li>
              <li class="li li-appointment-data">
                {{ appointment.typeOfBid }}
              </li>
              <li class="li li-appointment-data">
                {{ appointment.typeOfChallenging }}
              </li>
              <li
                :class="statusClass(appointment.status)"
                class="li li-appointment-data"
              >
                {{ statusText(appointment.status) }}
              </li>
            </ul>
          </div>
        </div>
        <Paginator
          v-model="page"
          :rows="10"
          :totalRecords="paginationAll"
          @page="getApplication($event.page)"
        ></Paginator>
      </div>
    </div>
  </div>
</template>

<script>
import UserNavigationComponent from "@/components/UserNavigationComponent.vue";
import UserDataComponent from "@/components/UserDataComponent.vue";
import axios from "axios";
import { mapState } from "vuex";
import Paginator from "primevue/paginator";

export default {
  components: {
    UserNavigationComponent,
    UserDataComponent,
    Paginator,
  },
  data() {
    return {
      appointments: [],
      paginationAll: 10,
      page: 0,
    };
  },
  methods: {
    statusClass(status) {
      return {
        "status-in-progress": status === "IN_PROGRESS",
        "status-completed": status === "COMPLETED",
      };
    },
    getApplication(page) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,

        "Content-Type": "multipart/form-data",
      };

      axios
        .get(
          `https://wb-backend-f9df5.amvera.io/application/find-all?size=10&page=${page}`,
          {
            headers,
          }
        )
        .then((response) => {
          this.appointments = response.data.content;
          this.paginationAll = response.data.totalPages * 10;
        })
        .catch((error) => console.log(error));
    },
    statusText(status) {
      switch (status) {
        case "SEND":
          return "Отправлено";
        case "IN_PROGRESS":
          return "В работе";
        case "COMPLETED":
          return "Исполнена";
        default:
          return status; // Возвращает оригинальный статус, если не совпадает
      }
    },
  },
  mounted() {
    this.getApplication(0);
    // this.$store.dispatch("fetchAppointments");
  },
  computed: {
    // ...mapState({
    //   appointments: (state) => state.appointments, // Получаем список заявок из Vuex
    // }),
  },
};
</script>

<style scoped>
.all-user-data {
  display: flex;
}

.user-data {
  width: 100%;
}

.user-applications {
  width: 100%;
}

.user-application {
  padding: 25px;
  border: 1px solid rgb(234, 228, 236);
  border-radius: 20px;
}

.user-application-title {
  color: black;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.all-appointments {
  margin-top: 20px;
}

.ul-appointment-title,
.ul-appointment-data {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  margin-bottom: 20px;
}

.ul-appointment-data {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.li {
  flex: 1;
  text-align: start; /* Выравнивание текста по центру */
}

.li-appointment {
  color: rgb(98, 98, 98);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.status-in-progress {
  color: orange;
}

.status-completed {
  color: green;
}

.video {
  display: grid;
}

.mini {
  display: none;
}

@media screen and (max-width: 1200px) {
  .li-appointment-data {
    font-size: 14px;
  }
  .ul-appointment-data {
    padding: 5px 0;
  }
}

@media screen and (max-width: 1000px) {
  .user-applications {
    display: none;
  }
  .mini {
    display: block;
  }
}

@media screen and (max-width: 780px) {
  .li-appointment {
    font-size: 11px;
  }
  .li-appointment-data {
    font-size: 12px;
  }
}

@media screen and (max-width: 680px) {
  .all-user-data {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .id {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .video {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  .li-appointment {
    font-size: 9px;
  }
  .li-appointment-data {
    font-size: 11px;
  }
}
</style>
