<template>
  <div>
    <div class="create-application">
      <p class="form-title">Создание заявки</p>
      <form class="form-submit" @submit.prevent="submitForm">
        <input
          class="form-data-input"
          type="text"
          placeholder="Номер ШК"
          required
          v-model="number"
        />
        <input
          class="form-data-input"
          type="number"
          placeholder="Сумма ШК"
          required
          v-model="sum"
        />
        <Select
          v-model="userId.id"
          :options="users"
          optionLabel="fullName"
          optionValue="id"
          placeholder="Выберите пользователя"
          checkmark
          class="w-full md:w-56"
          required
          @change="getPoints(userId.id)"
        />
        <Select
          v-model="selectedPoint"
          :options="points"
          optionLabel="addressPoint"
          placeholder="Выберите ПВЗ"
          checkmark
          class="w-full md:w-56"
          required
        />
        <Select
          v-model="selectedTypeOfBid"
          :options="typeOfBid"
          optionLabel="label"
          placeholder="Выберите тип заявки"
          checkmark
          class="w-full md:w-56"
          required
        />
        <Select
          v-model="status"
          :options="statusOptions"
          optionLabel="label"
          placeholder="Выберите статус"
          checkmark
          class="w-full md:w-56"
          required
        />
        <Select
          v-model="selectedTypeOfChallenging"
          :options="typeOfChallenging"
          optionLabel="label"
          placeholder="Выберите тип оспаривания"
          checkmark
          class="w-full md:w-56"
          required
        />
        <Textarea
          v-model="description"
          rows="5"
          autoResize
          cols="30"
          placeholder="Детали"
          required
        />
        <ButtonComponent
          :type="'submit'"
          :name="'Создать'"
          :style="'mini-button'"
        />
      </form>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent.vue";
import Select from "primevue/select";
import Textarea from "primevue/textarea";
import axios from "axios";

export default {
  components: {
    ButtonComponent,
    Select,
    Textarea,
  },
  data() {
    return {
      users: [], // Список пользователей
      points: [], // Список точек
      number: "", // Номер ШК
      sum: 0, // Сумма
      description: "", // Описание
      selectedPoint: null, // Выбранный ПВЗ
      selectedTypeOfBid: null, // Выбранный тип заявки
      selectedTypeOfChallenging: null, // Выбранный тип оспаривания
      userId: { id: null }, // Идентификатор пользователя
      pointId: { id: null }, // Идентификатор ПВЗ
      status: "",
      typeOfBid: [
        { label: "Ускоренный", value: "Ускоренный" },
        { label: "Обычный", value: "Обычный" },
      ],
      typeOfChallenging: [
        { label: "Брак", value: "Брак" },
        { label: "Зависший", value: "Зависший" },
        { label: "Подмена", value: "Подмена" },
        { label: "Штраф", value: "Штраф" },
      ],
      statusOptions: [
        { label: "SEND", value: "SEND" },
        { label: "IN_PROGRESS", value: "IN_PROGRESS" },
        { label: "COMPLETED", value: "COMPLETED" },
      ],
    };
  },
  methods: {
    submitForm() {
      // Собираем данные для отправки
      const newApplication = {
        number: this.number,
        sum: this.sum,
        description: this.description,
        pointId: {
          id: this.selectedPoint.id,
        },
        typeOfBid: this.selectedTypeOfBid.value,
        typeOfChallenging: this.selectedTypeOfChallenging.value,
        status: this.status.value, // Статус при создании
        userId: {
          id: this.userId.id, // ID пользователя (тестовый), замените на выбор из списка пользователей
        },
      };

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      // Отправляем данные на backend
      axios
        .post(
          "https://wb-backend-f9df5.amvera.io/application/create-admin",
          newApplication,
          { headers }
        )
        .then((response) => {
          console.log("Заявка создана:", response.data);
          // Можно добавить логику для уведомления или очистки формы после создания
        })
        .catch((error) => {
          console.error("Ошибка при создании заявки:", error);
        });
    },

    // Здесь можно добавить методы для получения списка пользователей и точек
    getPoints(id) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };
      // Запрос на получение ПВЗ
      axios
        .get(`https://wb-backend-f9df5.amvera.io/point/find-by-user/${id}`, {
          headers,
        })
        .then((response) => {
          this.points = response.data;
        })
        .catch((error) => console.log(error));
    },

    getUsers() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };
      // Запрос на получение пользователей
      axios
        .get("https://wb-backend-f9df5.amvera.io/user/small-admin", { headers })
        .then((response) => {
          this.users = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    // При монтировании компонента загружаем список точек и пользователей
    this.getUsers();
  },
};
</script>

<style scoped>
.create-application {
  display: grid;
  justify-items: center;
}

.form-submit {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.form-data-input {
  margin-bottom: 15px;
  border: none;
  padding: 15px;
  height: 45px;
  background-color: rgb(234, 228, 236);
  border-radius: 15px;
  color: rgb(97, 83, 103);
}

.form-data-input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgb(97, 83, 103);
}

.form-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 15px;
}
</style>
