<template>
  <div>
    <div class="register-container">
      <div class="register-title">Войти</div>
      <div class="register-description">Войдите в Ваш аккаунт</div>
      <div class="register-form">
        <form class="form-submin" @submit.prevent="submitForm">
          <input
            class="form-data-input"
            type="email"
            placeholder="E-Mail"
            required
            v-model="email"
          />
          <input
            class="form-data-input"
            type="login"
            placeholder="Логин"
            required
            v-model="loginData"
          />
          <input
            class="form-data-input"
            type="password"
            placeholder="Пароль"
            required
            v-model="password"
          />
          <ButtonComponent
            :type="'submit'"
            :name="'Войти'"
            :style="'mini-button'"
          />
        </form>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent.vue";
import axios from "axios";
import Toast from "primevue/toast";
import { jwtDecode } from "jwt-decode";

export default {
  components: {
    ButtonComponent,
    Toast,
  },
  data() {
    return {
      email: "",
      loginData: "",
      password: "",
    };
  },
  methods: {
    submitForm() {
      const formData = {
        email: this.email,
        login: this.login,
        password: this.password,
      };

      axios
        .post(
          "https://wb-backend-f9df5.amvera.io/auth/signin",
          JSON.stringify(formData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            // Получаем данные напрямую из response.data
            const data = response.data;
            localStorage.setItem("token", data.token);
            localStorage.setItem("refreshToken", data.refreshToken);

            const token = localStorage.getItem("token");
            const tokenDecoder = jwtDecode(token);
            const role = tokenDecoder.role;
            if (role[0].authority == "ADMIN") {
              // this.$router.push({ path: "/admin" });
              window.location.href = "/admin";
            } else if (role[0].authority == "MANAGER")
              window.location.href = "/admin/application";
            else window.location.href = "/user";
          }
        })
        .catch((error) => {
          console.log("Ошибка запроса:", error.response.data);
          if (
            error.response.data.errorMessage ==
            "Authentication failed: User is not verified."
          ) {
            this.showWarningNotCheck();
          } else if (
            error.response.data.errorMessage ==
            "Authentication failed: User is blocked."
          ) {
            this.showWarningBlocked();
          } else {
            this.showWarning();
          }
        });
    },

    showWarning() {
      this.$toast.add({
        severity: "warn",
        summary: "Ошибка!",
        detail: "Пользователь не найден",
        life: 5000,
      });
    },
    showWarningNotCheck() {
      this.$toast.add({
        severity: "warn",
        summary: "Ошибка!",
        detail: "Ваш аккаунт не подтверждён",
        life: 5000,
      });
    },
    showWarningBlocked() {
      this.$toast.add({
        severity: "warn",
        summary: "Ошибка!",
        detail: "Ваш аккаунт заблокирован",
        life: 5000,
      });
    },
  },
};
</script>

<style scoped>
.register-container {
  margin: 0 auto 0 auto;
  display: grid;
  justify-content: center;
}

.register-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 20px;
  text-align: center;
}

.register-description {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 39px;
  text-align: center;
}

.form-submin {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.form-data-input {
  margin-bottom: 15px;
  border: none;
  padding: 15px;
  height: 45px;
  background-color: rgb(234, 228, 236);
  border-radius: 15px;
  color: rgb(97, 83, 103);
}

.form-data-input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgb(97, 83, 103);
}
</style>
