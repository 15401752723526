<template>
  <div>
    <div class="user-all">
      <div class="user-title">Пользователи</div>
      <div class="user-subtitle">
        Нажмите на колонку ID, чтобы отредактировать
      </div>
      <div class="user-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Логин</th>
              <th>ФИО</th>
              <th>E-mail</th>
              <!-- <th>Пароль</th> -->
              <th>Баланс</th>
              <th>Роль</th>
              <th>Телефон</th>
              <th>Блокировка</th>
              <th>Проверка</th>
              <th>Тариф</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td @click="getAndUpdateUser(user.id)">{{ user.id }}</td>
              <td>{{ user.login }}</td>
              <td>{{ user.fullName }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.price }}₽</td>
              <td>{{ user.role }}</td>
              <td>{{ user.phone }}</td>
              <td>
                {{ user.isBlocked ? "Заблокирован" : "Не заблокирован" }}
              </td>
              <td>{{ user.isChecked ? "Проверен" : "Не проверен" }}</td>
              <td>{{ rateCase(user.rate) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Dialog
      v-model:visible="visible"
      modal
      header="Редактирование пользователя"
      :style="{ width: '25rem' }"
    >
      <form class="form-submin" @submit.prevent="updateUser">
        <!-- <p class="form-title">Создание заявки</p> -->
        <input
          class="form-data-input"
          type="text"
          placeholder="Логин"
          required
          v-model="user.login"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="ФИО"
          required
          v-model="user.fullName"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="E-main"
          required
          v-model="user.email"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="Пароль"
          v-model="user.password"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="Баланс"
          required
          v-model="user.price"
        />
        <Select
          v-model="selectedRole"
          :options="roles"
          optionLabel="name"
          placeholder="Роль пользователя"
          checkmark
          :highlightOnSelect="false"
          class="w-full md:w-56"
          required
          @change="user.role = selectedRole.value"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="Телефон"
          required
          v-model="user.phone"
        />
        <!-- radio -->
        <div class="mydict">
          <div>
            <label>
              <input
                type="radio"
                name="block"
                :value="true"
                v-model="user.isBlocked"
              />
              <span>Заблокирован</span>
            </label>
            <label>
              <input
                type="radio"
                name="block"
                :value="false"
                v-model="user.isBlocked"
              />
              <span>Не заблокирован</span>
            </label>
          </div>
        </div>

        <!-- radio -->
        <div class="mydict">
          <div>
            <label>
              <input
                type="radio"
                name="check"
                :value="true"
                v-model="user.isChecked"
              />
              <span>Проверен</span>
            </label>
            <label>
              <input
                type="radio"
                name="check"
                :value="false"
                v-model="user.isChecked"
              />
              <span>Не проверен</span>
            </label>
          </div>
        </div>

        <Select
          v-model="selectedRate"
          :options="rates"
          optionLabel="name"
          placeholder="Тариф"
          checkmark
          :highlightOnSelect="false"
          class="w-full md:w-56"
          required
          @change="user.rate = selectedRate.value"
        />
        <ButtonComponent
          :type="'submit'"
          :name="'Редактировать'"
          :style="'mini-button'"
        />
      </form>
    </Dialog>
    <Paginator
      v-model="page"
      :rows="10"
      :totalRecords="paginationAll"
      @page="getUsers($event.page)"
    ></Paginator>
  </div>
</template>

<script>
import axios from "axios";
import Paginator from "primevue/paginator";
import Dialog from "primevue/dialog";
import ButtonComponent from "../components/ButtonComponent.vue";
import Select from "primevue/select";
import Checkbox from "primevue/checkbox";

export default {
  components: {
    Paginator,
    Dialog,
    ButtonComponent,
    Select,
    Checkbox,
  },
  data() {
    return {
      users: [],
      paginationAll: 10,
      page: 0,
      visible: false,
      roles: [
        { name: "Пользователь", value: "USER" },
        { name: "Администратор", value: "ADMIN" },
        { name: "Менеджер", value: "MANAGER" },
      ],
      rates: [
        { name: "Тариф 1", value: "RATE_1" },
        { name: "Тариф 2", value: "RATE_2" },
        { name: "Тариф 3", value: "RATE_3" },
      ],
      user: {
        id: "",
        email: "",
        fullName: "",
        isBlocked: null, // Исправлено на null
        isChecked: null, // Исправлено на null
        login: "",
        phone: "",
        price: "",
        rate: "",
        role: "",
        password: "",
      },
      selectedRole: null,
      selectedRate: null,
    };
  },
  methods: {
    getUsers(page) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      axios
        .get(
          `https://wb-backend-f9df5.amvera.io/user/get-all?size=15&page=${page}`,
          {
            headers,
          }
        )
        .then((response) => {
          this.users = response.data.content;
          this.paginationAll = response.data.totalPages * 10;
          //   console.log(this.users);
        })
        .catch((error) => error);
    },
    rateCase(rate) {
      switch (rate) {
        case "RATE_1":
          return "Тариф 1";
        case "RATE_2":
          return "Тариф 2";
        case "RATE_3":
          return "Тариф 3";
        default:
          return rate; // Возвращает оригинальный статус, если не совпадает
      }
    },
    getAndUpdateUser(id) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      axios
        .get(`https://wb-backend-f9df5.amvera.io/user/get/${id}`, { headers })
        .then((response) => {
          const getUser = response.data;
          this.user.id = getUser.id;
          this.user.login = getUser.login;
          this.user.email = getUser.email;
          this.user.fullName = getUser.fullName;
          this.user.phone = getUser.phone;
          this.user.price = getUser.price;
          this.user.role = getUser.role;
          this.user.isBlocked = getUser.isBlocked;
          this.user.isChecked = getUser.isChecked;
          this.user.rate = getUser.rate;
          // this.user.password = getUser.password;

          // Находим объекты в массивах ролей и тарифов по значению value
          this.selectedRole = this.roles.find(
            (role) => role.value === getUser.role
          );
          this.selectedRate = this.rates.find(
            (rate) => rate.value === getUser.rate
          );

          this.visible = true;
        })
        .catch((error) => error);
    },
    updateUser() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      // Создаем объект для обновления, включая только измененные поля
      const updatedUser = {
        login: this.user.login,
        fullName: this.user.fullName,
        email: this.user.email,
        price: this.user.price,
        role: this.user.role,
        phone: this.user.phone,
        isBlocked: this.user.isBlocked,
        isChecked: this.user.isChecked,
        rate: this.user.rate,
        password: this.user.password,
      };

      // console.log(updatedUser);

      axios
        .patch(
          `https://wb-backend-f9df5.amvera.io/user/update/${this.user.id}`,
          updatedUser,
          {
            headers,
          }
        )
        .then((response) => {
          // console.log(response.data);
          this.visible = false; // Закрываем диалог
          this.getUsers(this.page); // Обновляем список пользователей
        })
        .catch((error) => error);
    },
    handOverUser(id) {
      // console.log(id);
    },
  },
  mounted() {
    this.getUsers(this.page);
  },
};
</script>

<style scoped>
.user-container {
  max-width: 100%;
  overflow-x: auto;
}

.user-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #dddddd42;
}

th {
  background-color: #f2f2f2;
  color: #333;
}

tr:hover {
  background-color: #f1f1f1;
}

.form-submin {
  display: flex;
  flex-direction: column;
  /* width: 280px; */
}

.form-title {
  color: rgb(40, 28, 45);
  margin-bottom: 5px;
}

.form-data-input {
  margin-bottom: 15px;
  border: none;
  padding: 15px;
  height: 45px;
  background-color: rgb(234, 228, 236);
  border-radius: 15px;
  color: rgb(97, 83, 103);
}

.form-data-input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgb(97, 83, 103);
}

/* Стили для radio button */
.mydict div {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  justify-content: center;
}

.mydict input[type="radio"] {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.mydict input[type="radio"]:checked + span {
  box-shadow: 0 0 0 0.0625em rgb(132, 10, 121);
  background-color: rgb(255, 242, 254);
  z-index: 1;
  color: rgb(132, 10, 121);
}

label span {
  display: block;
  cursor: pointer;
  background-color: #fff;
  padding: 0.375em 0.75em;
  position: relative;
  margin-left: 0.0625em;
  box-shadow: 0 0 0 0.0625em #b5bfd9;
  letter-spacing: 0.05em;
  color: #3e4963;
  text-align: center;
  transition: background-color 0.5s ease;
}

label:first-child span {
  border-radius: 0.375em 0 0 0.375em;
}

label:last-child span {
  border-radius: 0 0.375em 0.375em 0;
}

@media screen and (max-width: 600px) {
  th,
  td {
    padding: 10px;
    font-size: 14px;
  }
}
</style>
