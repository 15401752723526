<template>
    <div>
      <div class="all-user-data-nav">
        <nav class="user-nav">
          <ul class="user-ul">
            <li
              class="li li-user-nav"
              :class="{ active: isActive('/user') }"
              @click="navigateTo('/user')"
            >
              <img :src="require('../assets/img/home.png')" alt="home" />
              <p>Личный кабинет</p>
            </li>
            <li
              class="li li-user-nav li-user-nav-bottom"
              :class="{ active: isActive('/profile') }"
              @click="navigateTo('/profile')"
            >
              <img :src="require('../assets/img/profiles.png')" alt="profile" />
              <p>Профиль</p>
            </li>
            <!-- <li
              class="li li-user-nav li-user-nav-bottom"
              :class="{ active: isActive('/history') }"
              @click="navigateTo('/history')"
            >
              <img :src="require('../assets/img/history.png')" alt="history" />
              <p>История</p>
            </li> -->
            <!-- :class="{ active: isActive('/balance') }"
              @click="navigateTo('/balance')" -->
              <li
              class="li li-user-nav-payment"
              :class="{ active: isActive('/balance') }"
              @click="navigateTo('/balance')"
            >
              <p>Пополнить баланс</p>
            </li>
            <li
              class="li li-user-nav-payment"
              @click="logOut"
            >
              <p>Выйти</p>
            </li>
          </ul>
        </nav>
      </div>
      <!-- Это место, где будут отображаться выбранные страницы -->
      <!-- <router-view /> -->
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      navigateTo(route) {
        this.$router.push(route);
      },
      isActive(route) {
        return this.$route.path === route;
      },
      logOut() {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.href = "/"
      },

    }
  };
  </script>
  
  <style scoped>
  /* CSS стили остаются прежними */
  .all-user-data-nav {
      margin-right: 14px;
  }
  
  .user-ul {
    width: 249px;
    border: 1px solid rgb(234, 228, 236);
    border-radius: 20px;
  }
  
  .user-ul > li {
    cursor: pointer;
  }
  
  .active {
    background-color: rgb(234, 228, 236);
  }
  
  .li-user-nav {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 10px;
    margin: 20px 20px 15px 20px;
    border: 1px solid rgb(234, 228, 236);
  }
  
  .li-user-nav-bottom {
    margin-bottom: 303px;
  }
  
  .li-user-nav > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgb(40, 28, 45);
  }
  
  .li-user-nav > img {
    margin-right: 10px;
  }
  
  .li-user-nav-payment {
    border: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 10px;
    margin: 20px 20px 15px 20px;
  }
  
  .li-user-nav-payment > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgb(0, 0, 0);
  }

  @media screen and (max-width: 1100px) {
    .user-ul {
      width: 212px;
    }
  }

  @media screen and (max-width: 1000px) {
    .li-user-nav-bottom {
      margin-bottom: 0px;
    }
    .li-user-nav {
      padding: 10px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .li-user-nav-payment {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 680px) {
    .user-ul {
      width: 100%;
      margin-bottom: 20px;
    }
    .li-user-nav {
      justify-content: center;
    }
  }
  </style>
  