<template>
  <div>
    <ul class="example-1">
      <li class="icon-content">
        <a href="/" aria-label="Home" data-social="Home" class="link">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100"
            height="100"
            viewBox="0,0,256,256"
          >
            <g
              fill="#ffffff"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-weight="none"
              font-size="none"
              text-anchor="none"
              style="mix-blend-mode: normal"
            >
              <g transform="scale(5.33333,5.33333)">
                <path
                  d="M23.95117,4c-0.31984,0.01092 -0.62781,0.12384 -0.87891,0.32227l-14.21289,11.19727c-1.8039,1.42163 -2.85937,3.59398 -2.85937,5.89063v19.08984c0,1.36359 1.13641,2.5 2.5,2.5h10c1.36359,0 2.5,-1.13641 2.5,-2.5v-10c0,-0.29504 0.20496,-0.5 0.5,-0.5h5c0.29504,0 0.5,0.20496 0.5,0.5v10c0,1.36359 1.13641,2.5 2.5,2.5h10c1.36359,0 2.5,-1.13641 2.5,-2.5v-19.08984c0,-2.29665 -1.05548,-4.46899 -2.85937,-5.89062l-14.21289,-11.19727c-0.27738,-0.21912 -0.62324,-0.33326 -0.97656,-0.32227zM24,7.41016l13.28516,10.4668c1.0841,0.85437 1.71484,2.15385 1.71484,3.5332v18.58984h-9v-9.5c0,-1.91495 -1.58505,-3.5 -3.5,-3.5h-5c-1.91495,0 -3.5,1.58505 -3.5,3.5v9.5h-9v-18.58984c0,-1.37935 0.63074,-2.67883 1.71484,-3.5332z"
                ></path>
              </g>
            </g>
          </svg>
        </a>
        <div class="tooltip">Home</div>
      </li>
      <li class="icon-content">
        <a href="/user" aria-label="Users" data-social="Users" class="link">
          <svg
            class="icon"
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2.5a5.5 5.5 0 0 1 3.096 10.047 9.005 9.005 0 0 1 5.9 8.181.75.75 0 1 1-1.499.044 7.5 7.5 0 0 0-14.993 0 .75.75 0 0 1-1.5-.045 9.005 9.005 0 0 1 5.9-8.18A5.5 5.5 0 0 1 12 2.5ZM8 8a4 4 0 1 0 8 0 4 4 0 0 0-8 0Z"
            ></path>
          </svg>
        </a>
        <div class="tooltip">Users</div>
      </li>
      <li class="icon-content">
        <a href="/user" aria-label="User" data-social="User" class="link">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M13 20V18C13 15.2386 10.7614 13 8 13C5.23858 13 3 15.2386 3 18V20H13ZM13 20H21V19C21 16.0545 18.7614 14 16 14C14.5867 14 13.3103 14.6255 12.4009 15.6311M11 7C11 8.65685 9.65685 10 8 10C6.34315 10 5 8.65685 5 7C5 5.34315 6.34315 4 8 4C9.65685 4 11 5.34315 11 7ZM18 9C18 10.1046 17.1046 11 16 11C14.8954 11 14 10.1046 14 9C14 7.89543 14.8954 7 16 7C17.1046 7 18 7.89543 18 9Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
        </a>
        <div class="tooltip">Users</div>
      </li>
      
    </ul>
  </div>
</template>

<script></script>

<style scoped>
ul {
  list-style: none;
}

.example-1 {
  display: none;
  justify-content: center;
  align-items: center;
  font-weight: bolder; /* Made bolder */
  background-color: transparent; /* Black background */
  border-radius: 30px; /* Added border-radius to make the container rounded */
  padding: 20px; /* Added padding for better appearance */
  height: 70px;
  width: 300px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.example-1 .icon-content {
  margin: 0 10px;
  position: relative;
  font-weight: bolder; /* Made bolder */
}

.example-1 .icon-content .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff; /* White background */
  color: #000; /* Black text */
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
  font-weight: bolder; /* Made bolder */
}

.example-1 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}

.example-1 .icon-content .link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff; /* White icons */
  background-color: #ffc6ff; /* Black background */
  transition: all 0.3s ease-in-out;
  font-weight: bolder; /* Made bolder */
}

.example-1 .icon-content .link:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}

.example-1 .icon-content .link svg {
  width: 30px;
  height: 30px;
  fill: #fff; /* White icons */
}

.example-1 .icon-content .link[data-social="spotify"]:hover {
  color: #1db954;
}

.example-1 .icon-content .link[data-social="pinterest"]:hover {
  color: #bd081c;
}

.example-1 .icon-content .link[data-social="dribbble"]:hover {
  color: #ea4c89;
}

.example-1 .icon-content .link[data-social="telegram"]:hover {
  color: #0088cc;
}

@media screen and (max-width: 850px) {
  .example-1 {
    display: flex;
  }
}
</style>
