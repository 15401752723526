<template>
    <div>
        <footer>
          <div class="footer-container">
            <div class="footer-title"><img :src="require('../assets/img/logo-footer.png')" alt="Логотип"></div>
            <div class="footer-list">
              <div class="footer-nav">
                <div class="footer-nav-1">
                  <ul class="footer-ul">
                    <li><a href="" class="link">О сайте</a></li>
                    <li><a href="" class="link">Тарифы</a></li>
                    <li><a href="" class="link">Оферта</a></li>
                  </ul>
                </div>
                <div class="footer-nav-2">
                  <ul class="footer-ul">
                    <li><a href="" class="link">Регистрация</a></li>
                    <li><a href="" class="link">Вход</a></li>
                  </ul>
                </div>
              </div>
<!--              Ссылки на соц. сети-->
              <div class="footer-nav">
                <div class="footer-nav-1">
                  <ul class="footer-ul">
                    <li><a href="" class="link">Telegram</a></li>
                    <li><a href="" class="link">Viber</a></li>
                  </ul>
                </div>
                <div class="footer-nav-2">
                  <ul class="footer-ul">
                    <li><a href="" class="link">xxx@mail.ru</a></li>
                    <li><a href="" class="link">+79999999999</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
    </div>
</template>
<script setup lang="ts">
</script>

<style scoped>
footer {
  padding: 70px 0 100px 0;
  background-color: #f4f4f4;
  margin-top: 100px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-title {
  margin-bottom: 66px;
}

.footer-ul > li {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 40px;
}

.footer-ul > li > a {
  color: #898989;
}

.footer-ul > li:last-child {
  margin-bottom: 0;
}

.footer-nav-1 {
  margin-right: 178px;
}

.footer-nav {
  display: flex;
}

.footer-list {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .footer-container {
    padding: 0 50px;
  }
}

@media screen and (max-width: 800px) {
  .footer-nav-1 {
    margin-right: 50px;
  }
}

@media screen and (max-width: 545px) {
  .footer-nav {
    flex-direction: column;
  }
  .footer-nav > .footer-nav-1 {
    margin-bottom: 20px;
  }
}
</style>