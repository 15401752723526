<template>
  <div class="button-container">
    <button :class="style" :type="type" :disabled="disabled">{{ name }}</button>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    type: String,
    style: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.button {
  background-image: linear-gradient(
    to right,
    rgb(183, 0, 167),
    rgb(132, 10, 121)
  ); /* Градиент слева направо */
  color: white;
  padding: 10px 20px 10px 20px;
  border: none;
  transition: background-color 0.4s cubic-bezier(0, 0.55, 0.45, 1) 0s;
  cursor: pointer;
  font-size: 16px;
  border-radius: 20px;
  font-weight: 500;
  width: 142px;
  height: 42px;
}

.button-white {
    width: 100%;
    height: 47px;
    color: #252525;
    background-color: white;
    border-radius: 30px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    border: none;
    cursor: pointer;
}

.mini-button {
  padding: 15px;
  background-color: rgb(171, 30, 158);
  color: white;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  border-radius: 15px;
  height: 45px;
  width: 100%;
}

.mini-button-gray {
  padding: 15px;
  background-color: rgb(175, 175, 175);
  color: white;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  border-radius: 15px;
  height: 45px;
  width: 100%;
}
</style>
