<template>
  <div>
    <div class="put-balance">
      <UserNavigationComponent />
      <div class="balance-container">
        <UserDataComponent />
        <div class="replenishment-container">
          <div class="replenishment-title">Пополнение</div>
          <div class="replenishment-input">
            <div class="replenishment-input-title">Сумма пополнения</div>
            <form class="form-submit" @submit.prevent="submitForm">
              <InputNumber
                v-model="amount"
                placeholder="Сумма"
                inputId="currency-ru"
                mode="currency"
                currency="RUB"
                locale="ru-RU"
                fluid
                required
              />
              <ButtonComponent
                :type="'submit'"
                :name="'Перейти к оплате'"
                :style="'mini-button'"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserNavigationComponent from "@/components/UserNavigationComponent.vue";
import UserDataComponent from "@/components/UserDataComponent.vue";
import InputNumber from "primevue/inputnumber";
import ButtonComponent from "@/components/ButtonComponent.vue";
import Toast from "primevue/toast";
import { inject } from "vue"; // Для inject
import axios from "axios";

export default {
  components: {
    UserNavigationComponent,
    UserDataComponent,
    InputNumber,
    ButtonComponent,
    Toast,
  },
  setup() {
    // Inject функции updateBalance
    const updateBalance = inject("updateBalance");
    return {
      updateBalance,
    };
  },
  data() {
    return {
      amount: 50,
    };
  },
  methods: {
    submitForm() {
      if (this.amount < 50) {
        this.showWarning();
      } else {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        axios
          .patch("https://wb-backend-f9df5.amvera.io/user/payment", null, {
            headers,
            params: {
              amount: this.amount, // Передаем как параметр запроса
            },
          })
          .then((response) => {
            if (response.status == 200) {
              this.showSuccess();
              this.updateBalance();
            }
          })
          .catch((error) => {
            console.log("Ошибка запроса:", error);
            this.showNotPaymend();
          });
      }
    },
    showSuccess() {
      this.$toast.add({
        severity: "success",
        summary: "Оплата прошла успешно!",
        detail: "Баланс пополнится в ближайшее время",
        life: 3000,
      });
    },
    showWarning() {
      this.$toast.add({
        severity: "warn",
        summary: "Минимальная сумма 50₽",
        detail: "",
        life: 3000,
      });
    },
    showNotPaymend() {
      this.$toast.add({
        severity: "warn",
        summary: "Оплата не прошла",
        detail: "Повторите попытку",
        life: 5000,
      });
    },
  },
};
</script>

<style scoped>
.put-balance {
  display: flex;
}

.balance-container {
  width: 100%;
}

.replenishment-container {
  padding: 25px;
  border: 1px solid rgb(234, 228, 236);
  border-radius: 20px;
}

.replenishment-title {
  color: black;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.replenishment-input {
  margin-top: 20px;
}

.replenishment-input-title {
  color: rgb(137, 137, 137);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}

.form-submit {
  display: grid;
}

@media screen and (max-width: 680px) {
  .put-balance {
    flex-direction: column;
  }
}
</style>
