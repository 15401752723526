<template>
  <div>
    <div class="all-user-data">
      <UserNavigationComponent />
      <div class="user-data">
        <UserDataComponent />
        <div class="all-point">
          <div class="points">
            <div class="point-title">Настройки</div>
            <div class="point-container">
              <div class="all-point-create">
                <div class="point-create-title">Пункты выдачи</div>
                <div
                  class="point-create-container"
                  v-for="point in points"
                  :key="point.id"
                >
                  <div class="point-create" @click="getPointById(point.id)">
                    {{ point.addressPoint }}
                    <span @click="deletePoint(point.id)"
                      ><svg
                        width="10.605469"
                        height="10.607422"
                        viewBox="0 0 10.6055 10.6074"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <desc>Created with Pixso.</desc>
                        <defs />
                        <path
                          id="Vector"
                          d="M10.25 0.35L0.35 10.25M10.25 10.25L0.35 0.35"
                          stroke="#898989"
                          stroke-opacity="1.000000"
                          stroke-width="1.000000"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="point-create-form" @click="visible = true">
                  Добавить новый ПВЗ
                  <span
                    ><svg
                      width="14.000000"
                      height="14.000000"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <desc>Created with Pixso.</desc>
                      <defs />
                      <path
                        id="Vector"
                        d="M7 0L7 14M14 7L0 7"
                        stroke="#898989"
                        stroke-opacity="1.000000"
                        stroke-width="1.000000"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div class="point-inform">
                <div class="point-inform-title">Информация</div>
                <div class="point-inform-container" v-if="!isChange">
                  <div class="point-inform-content">
                    <p class="title">Адрес</p>
                    <p class="data">{{ addressPoint }}</p>
                  </div>
                  <div class="point-inform-content">
                    <p class="title">ID</p>
                    <p class="data">{{ pointIdPoint }}</p>
                  </div>
                  <div class="point-inform-content">
                    <p class="title">Сервис</p>
                    <p class="data">{{ namePoint }}</p>
                  </div>
                  <div class="point-inform-content">
                    <p class="title">Логин</p>
                    <p class="data">{{ loginPoint }}</p>
                  </div>
                  <div class="point-inform-content">
                    <p class="title">Пароль</p>
                    <p class="data">{{ passPoint }}</p>
                  </div>
                </div>
                <div class="point-inform-container" v-if="isChange">
                  <div class="point-inform-content">
                    <p class="title">Адрес</p>
                    <input
                      type="text"
                      class="data input-data"
                      v-model="addressPoint"
                    />
                  </div>
                  <div class="point-inform-content">
                    <p class="title">ID</p>
                    <input
                      type="text"
                      class="data input-data"
                      v-model="pointIdPoint"
                    />
                  </div>
                  <div class="point-inform-content">
                    <p class="title">Сервис</p>
                    <input
                      type="text"
                      class="data input-data"
                      v-model="namePoint"
                    />
                  </div>
                  <div class="point-inform-content">
                    <p class="title">Логин</p>
                    <input
                      type="text"
                      class="data input-data"
                      v-model="loginPoint"
                    />
                  </div>
                  <div class="point-inform-content">
                    <p class="title">Пароль</p>
                    <input
                      type="text"
                      class="data input-data"
                      v-model="passPoint"
                    />
                  </div>
                </div>

                <div
                  class="buttons"
                  style="display: flex; justify-content: center; width: 100%"
                >
                  <ButtonComponent
                    :name="isChange ? 'Сохранить' : 'Редактировать'"
                    :type="'button'"
                    :style="'mini-button-gray'"
                    @click="isChange ? updatePoint(id) : (isChange = true)"
                  />
                  <ButtonComponent
                    v-if="isChange"
                    :name="'Отменить'"
                    :type="'button'"
                    :style="'mini-button-gray'"
                    @click="isChange = false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="all-point mini">
      <div class="points">
        <div class="point-title">Настройки</div>
        <div class="point-container">
          <div class="all-point-create">
            <div class="point-create-title">Пункты выдачи</div>
            <div
              class="point-create-container"
              v-for="point in points"
              :key="point.id"
            >
              <div class="point-create" @click="getPointById(point.id)">
                {{ point.addressPoint }}
                <span @click="deletePoint(point.id)"
                  ><svg
                    width="10.605469"
                    height="10.607422"
                    viewBox="0 0 10.6055 10.6074"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <desc>Created with Pixso.</desc>
                    <defs />
                    <path
                      id="Vector"
                      d="M10.25 0.35L0.35 10.25M10.25 10.25L0.35 0.35"
                      stroke="#898989"
                      stroke-opacity="1.000000"
                      stroke-width="1.000000"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="point-create-form" @click="visible = true">
              Добавить новый ПВЗ
              <span
                ><svg
                  width="14.000000"
                  height="14.000000"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <desc>Created with Pixso.</desc>
                  <defs />
                  <path
                    id="Vector"
                    d="M7 0L7 14M14 7L0 7"
                    stroke="#898989"
                    stroke-opacity="1.000000"
                    stroke-width="1.000000"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div class="point-inform">
            <div class="point-inform-title">Информация</div>
            <div class="point-inform-container" v-if="!isChange">
              <div class="point-inform-content">
                <p class="title">Адрес</p>
                <p class="data">{{ addressPoint }}</p>
              </div>
              <div class="point-inform-content">
                <p class="title">ID</p>
                <p class="data">{{ pointIdPoint }}</p>
              </div>
              <div class="point-inform-content">
                <p class="title">Сервис</p>
                <p class="data">{{ namePoint }}</p>
              </div>
              <div class="point-inform-content">
                <p class="title">Логин</p>
                <p class="data">{{ loginPoint }}</p>
              </div>
              <div class="point-inform-content">
                <p class="title">Пароль</p>
                <p class="data">{{ passPoint }}</p>
              </div>
            </div>
            <div class="point-inform-container" v-if="isChange">
              <div class="point-inform-content">
                <p class="title">Адрес</p>
                <input
                  type="text"
                  class="data input-data"
                  v-model="addressPoint"
                />
              </div>
              <div class="point-inform-content">
                <p class="title">ID</p>
                <input
                  type="text"
                  class="data input-data"
                  v-model="pointIdPoint"
                />
              </div>
              <div class="point-inform-content">
                <p class="title">Сервис</p>
                <input
                  type="text"
                  class="data input-data"
                  v-model="namePoint"
                />
              </div>
              <div class="point-inform-content">
                <p class="title">Логин</p>
                <input
                  type="text"
                  class="data input-data"
                  v-model="loginPoint"
                />
              </div>
              <div class="point-inform-content">
                <p class="title">Пароль</p>
                <input
                  type="text"
                  class="data input-data"
                  v-model="passPoint"
                />
              </div>
            </div>

            <div
              class="buttons"
              style="display: flex; justify-content: center; width: 100%"
            >
              <ButtonComponent
                :name="isChange ? 'Сохранить' : 'Редактировать'"
                :type="'button'"
                :style="'mini-button-gray'"
                @click="isChange ? updatePoint(id) : (isChange = true)"
              />
              <ButtonComponent
                v-if="isChange"
                :name="'Отменить'"
                :type="'button'"
                :style="'mini-button-gray'"
                @click="isChange = false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog
      v-model:visible="visible"
      modal
      header="Добавить ПВЗ"
      :style="{ width: '25rem' }"
    >
      <form class="form-submin" @submit.prevent="createNewPoint">
        <input
          class="form-data-input"
          type="text"
          placeholder="Адрес пункта выдачи"
          required
          v-model="point.addressPoint"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="ID пункта выдачи "
          required
          v-model="point.pointIdPoint"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="Ссылка на сервис видеонаблюдения"
          required
          v-model="point.namePoint"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="Логин"
          required
          v-model="point.loginPoint"
        />
        <input
          class="form-data-input"
          type="text"
          placeholder="Пароль"
          required
          v-model="point.passPoint"
        />
        <ButtonComponent
          :type="'submit'"
          :name="'Добавить'"
          :style="'mini-button'"
        />
      </form>
    </Dialog>
    <Toast />
  </div>
</template>

<script>
import UserNavigationComponent from "@/components/UserNavigationComponent.vue";
import UserDataComponent from "@/components/UserDataComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import Dialog from "primevue/dialog";
import axios from "axios";
import Toast from "primevue/toast";

export default {
  components: {
    UserNavigationComponent,
    UserDataComponent,
    ButtonComponent,
    Dialog,
    Toast,
  },
  data() {
    return {
      points: [],
      visible: false,
      id: "",
      pointIdPoint: "",
      addressPoint: "",
      loginPoint: "",
      namePoint: "",
      passPoint: "",
      point: {
        pointIdPoint: "",
        addressPoint: "",
        loginPoint: "",
        namePoint: "",
        passPoint: "",
      },
      change: {
        pointIdPoint: "",
        addressPoint: "",
        loginPoint: "",
        namePoint: "",
        passPoint: "",
      },
      isChange: false,
    };
  },
  methods: {
    getPoint() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };
      axios
        .get("https://wb-backend-f9df5.amvera.io/point/find-all", {
          headers,
        })
        .then((response) => {
          this.points = response.data;
        })
        .catch((error) => console.log(error));
    },
    getPointById(pointId) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };
      axios
        .get("https://wb-backend-f9df5.amvera.io/point/find/" + pointId, {
          headers,
        })
        .then((response) => {
          let data = response.data;
          this.id = data.id;
          this.pointIdPoint = data.pointIdPoint;
          this.addressPoint = data.addressPoint;
          this.loginPoint = data.loginPoint;
          this.namePoint = data.namePoint;
          this.passPoint = data.passPoint;
        })
        .catch((error) => console.log(error));
    },
    createNewPoint() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      axios
        .post("https://wb-backend-f9df5.amvera.io/point/create", this.point, {
          headers,
        })
        .then((response) => {
          // После успешного создания нового пункта обновляем список
          this.getPoint();
          this.visible = false;
          // Очищаем форму
          this.point = {
            pointIdPoint: "",
            addressPoint: "",
            namePoint: "",
            loginPoint: "",
            passPoint: "",
          };
        })
        .catch((error) => console.log(error));
    },
    updatePoint(id) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const updatedPoint = {
        id: this.id, // или просто id если он не меняется
        pointIdPoint: this.pointIdPoint,
        addressPoint: this.addressPoint,
        loginPoint: this.loginPoint,
        namePoint: this.namePoint,
        passPoint: this.passPoint,
      };

      axios
        .patch(
          `https://wb-backend-f9df5.amvera.io/point/update`,
          updatedPoint,
          {
            headers,
          }
        )
        .then((response) => {
          // После обновления данных можно обновить список пунктов или отобразить сообщение
          this.isChange = false;
          this.getPoint();
          this.showSuccess();
        })
        .catch((error) => {
          console.error(error);
          this.showWarning();
        });
    },
    deletePoint(id) {
      if (confirm("Вы уверены, что хотите удалить этот ПВЗ?")) {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        axios
          .delete("https://wb-backend-f9df5.amvera.io/point/delete/" + id, {
            headers,
          })
          .then((response) => {
            this.points = this.points.filter((point) => point.id !== id);
          })
          .catch((error) => console.log(error));
      }
    },
    showSuccess() {
      this.$toast.add({
        severity: "success",
        summary: "Данные успешно обновлены!",
        detail: "",
        life: 3000,
      });
    },
    showWarning() {
      this.$toast.add({
        severity: "warn",
        summary: "Ошибка при обвнолении данных",
        detail: "Повторите попытку",
        life: 3000,
      });
    },
    showNotSave() {
      this.$toast.add({
        severity: "warn",
        summary: "Вы не сохранили данные",
        detail: "",
        life: 3000,
      });
    },
    handleBeforeUnload(event) {
      if (this.isChange) {
        event.preventDefault();
        this.showNotSave(); // Показываем ваше кастомное уведомление
        event.returnValue = ""; // Оставляем пустое значение для предотвращения стандартного поведения
      }
    },
  },
  mounted() {
    this.getPoint();
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChange) {
      this.showNotSave(); // Показываем уведомление
      next(false); // Отменяем переход
    } else {
      next(); // Разрешаем переход, если изменений нет
    }
  },
};
</script>

<style scoped>
.all-user-data {
  display: flex;
}

.user-data {
  width: 100%;
}

.all-point {
  padding: 25px;
  border-radius: 20px;
  border: 1px solid rgb(234, 228, 236);
}

.point-title {
  color: black;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
  width: 438px;
}

.point-create-title {
  color: rgb(137, 137, 137);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
  width: 438px;
}

.point-create {
  border: 1px solid rgb(227, 227, 227);
  padding: 13px 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.all-point-create {
  height: 360px;
  overflow-y: scroll;
  scrollbar-width: thin; /* Для Firefox */
  scroll-behavior: smooth; /* Плавная прокрутка */
  scrollbar-color: #888 #f1f1f1; /* Цвет скроллбара и фона для Firefox */
}

/* Для WebKit-браузеров (Chrome, Safari) */
.all-point-create::-webkit-scrollbar {
  width: 8px; /* Ширина скроллбара */
}

.all-point-create::-webkit-scrollbar-track {
  background: #f1f1f1; /* Цвет фона скроллбара */
  border-radius: 10px; /* Закругление углов фона */
}

.all-point-create::-webkit-scrollbar-thumb {
  background: #888; /* Цвет ползунка */
  border-radius: 10px; /* Закругление углов ползунка */
}

.all-point-create::-webkit-scrollbar-thumb:hover {
  background: #555; /* Цвет ползунка при наведении */
}

.point-create > span {
  cursor: pointer;
}

.point-create-container {
  width: 438px;
}

.point-create-form {
  padding: 13px 10px;
  background-color: rgb(64, 64, 64);
  border: 1px solid rgb(227, 227, 227);
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

.point-create-form > span {
  margin-left: 5px;
}

.point-container {
  display: flex;
  justify-content: space-between;
}

.point-inform-title {
  color: black;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
}

.point-inform {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 10px;
  width: 367px;
}

.point-inform-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.point-inform-content > p {
  color: rgb(147, 147, 147);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.point-inform-container {
  margin-bottom: 172px;
}

.form-submin {
  display: flex;
  flex-direction: column;
  /* width: 280px; */
}

.form-title {
  color: rgb(40, 28, 45);
  margin-bottom: 5px;
}

.form-data-input {
  margin-bottom: 15px;
  border: none;
  padding: 15px;
  height: 45px;
  background-color: rgb(234, 228, 236);
  border-radius: 15px;
  color: rgb(97, 83, 103);
}

.form-data-input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgb(97, 83, 103);
}

.input-data {
  border: none;
  background-color: rgb(234, 228, 236);
  color: rgb(97, 83, 103);
  padding: 10px;
  border-radius: 15px;
  width: 230px;
}

.buttons {
  width: 100%;
}

.buttons > div {
  width: 100%;
}

.mini {
  display: none;
}

@media screen and (max-width: 1178px) {
  .point-inform {
    width: 300px;
  }
}

@media screen and (max-width: 1078px) {
  .point-create-container,
  .point-create-form,
  .point-create-title {
    width: 400px;
  }
}

@media screen and (max-width: 1000px) {
  .all-point {
    display: none;
  }
  .mini {
    display: block;
  }
}

@media screen and (max-width: 800px) {
  .point-create-container,
  .point-create-form,
  .point-create-title {
    width: 300px;
  }

  .point-create-container,
  .point-create-form {
    font-size: 12px;
  }
}

@media screen and (max-width: 700px) {
  .point-create-container,
  .point-create-form,
  .point-create-title {
    width: 260px;
  }

  .point-create-container,
  .point-create-form {
    font-size: 12px;
  }
}

@media screen and (max-width: 680px) {
  .all-user-data {
    flex-direction: column;
  }
  .point-inform-content > .data,
  .point-inform-content > .title {
    font-size: 12px;
  }
  .point-inform {
    width: 280px;
  }
  .data {
    width: 160px;
    word-break: break-word;
  }
}

@media screen and (max-width: 640px) {
  .point-container {
    flex-wrap: wrap;
  }
  .all-point-create {
    margin-bottom: 20px;
  }
  .point-create-container,
  .point-create-form {
    width: 100%;
    max-width: 500px;
  }
  .all-point-create {
    width: 100%;
  }
  .point-inform {
    width: 100%;
  }
  .point-create-container,
  .point-create-form {
    font-size: 16px;
  }
  .point-inform-content > .data,
  .point-inform-content > .title {
    font-size: 16px;
  }
}
</style>
